import React from "react";
import { Grid, Typography, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { uiTexts } from "../../../Utils/uiTexts";
import { ReportActionButton } from "./ReportActionsButton";
import { ReportFields } from "../../ReportPage Details/Filters Details/ReportFields";
import "../../Report.css";

// ===================================================|| REPORT FILTERS ||=================================================== //

export const CommonFilters = ({
  reportType,
  reportDate,
  handleFieldChange,
  isClearDisabled,
  isDownloadDisabled,
  handleClear,
  handleDownload,
}) => {
  const {
    placeHolderTexts: { chooseReportText, chooseDateText, dateInputFormat },
  } = uiTexts;

  const reportsType = [
    { id: 1, name: "Report 1" },
    { id: 2, name: "Report 2" },
    { id: 3, name: "Dispatch report" },
  ];

  return (
    <Grid container item xs={12} sx={{ ml: 2 }}>
      {/* Report Type */}
      <ReportFields
        label={chooseReportText}
        placeHolder={chooseReportText}
        select
        value={reportType}
        onChange={(e) => handleFieldChange(chooseReportText, e.target.value)}
        options={reportsType?.map((report) => ({
          label: report.name,
          value: report.id,
        }))}
      />

      {/* Date and Time */}
      <Grid item sx={{ ml: 6.9 }}>
        <Typography className="bill-modal-text">{chooseDateText}</Typography>
      </Grid>

      <Grid item className="report-date-picker" sx={{ ml: 11 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={chooseDateText}
            format={dateInputFormat}
            value={reportDate}
            onChange={(newValue) => handleFieldChange(chooseDateText, newValue)}
            renderInput={(params) => <TextField {...params} fullWidth />}
            slotProps={{
              textField: {
                readOnly: true,
              },
            }}
          />
        </LocalizationProvider>
      </Grid>

      {(reportType === 1 || reportType === 3) && (
        <ReportActionButton
          isClearDisabled={isClearDisabled}
          isDownloadDisabled={isDownloadDisabled}
          handleClear={handleClear}
          handleDownload={handleDownload}
        />
      )}
    </Grid>
  );
};
