import React from "react";
import { TableBody, TableCell, TableRow } from "@mui/material";
import { useSelector } from "react-redux";
import { handleUnknown } from "../../../Utils/common";
import { uiTexts } from "../../../Utils/uiTexts";

// ============================================|| REPORT TABLE ITEMS ||============================================ //

export const ReportTableItem = () => {
  const {
    labels: { unKnownText },
    separators: { hyphenText },
  } = uiTexts;

  const { selectedReportData } = useSelector((state) => state.data_menu);

  const getHeaders = (data) => {
    if (data?.length > 0) {
      return Object.keys(data[0]);
    }
    return [];
  };

  const headers = getHeaders(selectedReportData);

  return (
    <TableBody>
      {selectedReportData?.map((data, index) => (
        <TableRow key={index}>
          {headers?.map((header, headerIndex) => (
            <TableCell
              key={headerIndex}
              className="bill-modal-font remove-border-bottom"
            >
              {handleUnknown(unKnownText, data[header], hyphenText)}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};
