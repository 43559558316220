import React from "react";
import { Typography } from "@mui/material";
import "./WarningPage.css";

// =========================================|| Page Not Found ||========================================== //

const WarningPage = ({ warningLabel }) => {
  return (
    <div className="no-access">
      <Typography className="no-access-text">{warningLabel}</Typography>
    </div>
  );
};

export default WarningPage;
