import React from "react";
import { Grid, Typography, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { uiTexts } from "../../../Utils/uiTexts";
import { handleDateSelect } from "../../../Utils/common";
import { OrderFields } from "./OrderFields";
import "../../OrderPage.css";

// ===================================================|| BILL INPUT FIELDS ||=================================================== //

export const BillInputFields = ({
  billNumber,
  selectedFirm,
  userName,
  userComments,
  selectedDate,
  setUpdateDate,
  setSelectedDate,
  handleFieldChange,
  userNameError,
  isEditBillModal,
}) => {
  const { firmNames } = useSelector((state) => state.data_menu);
  const {
    labels: { billNumberText, firmNameText, userNameText, yourCommentsText },
    placeHolderTexts: {
      enterBillNumberText,
      chooseFirmText,
      enterUserNameText,
      addYourCommentsText,
      chooseDateAndTimeText,
      dateTimeInputFormat,
    },
  } = uiTexts;

  return (
    <Grid container spacing={2} className="order-details-modal-table">
      {/* Bill Number */}
      <OrderFields
        label={billNumberText}
        placeHolder={enterBillNumberText}
        value={billNumber}
        onChange={(e) => handleFieldChange(billNumberText, e.target.value)}
        xs1={2.5}
        xs2={3.5}
        isEditBillModal={isEditBillModal}
      />

      {/* Firm Name */}
      <OrderFields
        label={firmNameText}
        placeHolder={chooseFirmText}
        value={selectedFirm}
        onChange={(e) => handleFieldChange(firmNameText, e.target.value)}
        select
        options={firmNames?.map((firm) => ({
          label: firm.firmName,
          value: firm.id,
        }))}
        xs1={2.5}
        xs2={3.5}
      />

      {/* User Name */}
      <OrderFields
        label={userNameText}
        placeHolder={enterUserNameText}
        value={userName}
        onChange={(e) => handleFieldChange(userNameText, e.target.value)}
        xs1={2.5}
        xs2={3.5}
        errorText={userNameError}
      />

      {/* Date and Time */}
      <Grid item xs={2.5}>
        <Typography className="bill-modal-text">
          {chooseDateAndTimeText}
        </Typography>
      </Grid>
      <Grid item xs={3.5} className="custom-date-picker">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label={chooseDateAndTimeText}
            value={selectedDate}
            format={dateTimeInputFormat}
            onChange={(newValue) =>
              handleDateSelect(newValue, setUpdateDate, setSelectedDate)
            }
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>
      </Grid>

      {/* Comments */}
      <OrderFields
        label={yourCommentsText}
        placeHolder={addYourCommentsText}
        value={userComments}
        onChange={(e) => handleFieldChange(yourCommentsText, e.target.value)}
        multiline
        rows={2}
        xs1={2.5}
        xs2={3.5}
      />
    </Grid>
  );
};
