import React from "react";
import { Grid } from "@mui/material";
import { OrderFields } from "../../Bill Details/OrderFields";
import { uiTexts } from "../../../../Utils/uiTexts";
import { useSelector } from "react-redux";
import { CustomerResponsibility } from "../../Bill Details/CustomerResponsibility";

// ===================================================|| BILL FORM ||=================================================== //

export const TransportInputFields = ({
  customerResponsibility,
  selectedTransport,
  handleFieldChange,
  isEditBillModal,
}) => {
  const {
    labels: { transportNameText, transportText },
    placeHolderTexts: { chooseTransportText },
    buttonText: { noText },
  } = uiTexts;

  const { transportData } = useSelector((state) => state.data_menu);

  return (
    <>
      <Grid
        container
        spacing={2}
        className="order-details-modal-table bill-modal-transport-table"
      >
        <CustomerResponsibility
          customerResponsibility={customerResponsibility}
          handleFieldChange={handleFieldChange}
          isEditBillModal={isEditBillModal}
        />
      </Grid>

      {customerResponsibility === noText && (
        <Grid
          container
          spacing={2}
          className="order-details-modal-table bill-modal-transport-table"
        >
          {/* Transport Name */}
          <OrderFields
            label={transportNameText}
            placeHolder={chooseTransportText}
            value={selectedTransport}
            onChange={(e) => handleFieldChange(transportText, e.target.value)}
            select
            options={transportData?.map((transport) => ({
              label: transport.transportName,
              value: transport.id,
            }))}
            xs1={2.5}
            xs2={3.5}
          />
        </Grid>
      )}
    </>
  );
};
