import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { BillModal } from "../MoveToBill Modal/BillModal";
import { Loader } from "../../Loader/Loader";
import { CustomerOrderHeaderDetails } from "../PendingCustomerOrdersDetails/CustomerOrderHeader/CustomerOrderHeaderDetails";
import { CustomerOrderTable } from "./CustomerOrderTableDetails/CustomerOrderTable";
import { getCustomerGroup } from "../../Services/DataService";
import useRetrieveFunctions from "../../CustomHooks/useRetrieveFunctions";

// ===================================================|| CUSTOMER ORDER ||=================================================== //

export const CustomerOrder = ({
  selectedCustomer,
  setSelectedCustomer,
  orderItems,
  setOrderItems,
  setIsOpenAlert,
}) => {
  const { retrieveTransportData } = useRetrieveFunctions();

  const [billClick, setBillClick] = useState(false);
  const [customerDetailsSelect, setCustomerDetailsSelect] = useState([]);
  const [partyGroups, setPartyGroups] = useState({ data: [], status: null });

  useEffect(() => {
    setBillClick(false);
    setCustomerDetailsSelect([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer]);

  const handleChange = (id) => {
    if (!customerDetailsSelect.includes(id)) {
      setCustomerDetailsSelect((prevState) => [...prevState, id]);
    } else {
      setCustomerDetailsSelect((prevState) =>
        prevState.filter((item) => item !== id),
      );
    }
  };

  useEffect(() => {
    const fetchCustomerGroup = async () => {
      if (selectedCustomer?.partyId) {
        try {
          const { data, status } = await getCustomerGroup(
            selectedCustomer.partyId,
          );
          setPartyGroups({ data, status });
        } catch (error) {
          console.error(
            "An error occurred while fetching customer group data:",
            error,
          );
          setPartyGroups({ data: [], status: "error" });
        }
      }
    };

    if (billClick) {
      fetchCustomerGroup();
      retrieveTransportData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer, billClick]);

  return (
    <>
      {orderItems?.length === 0 ? (
        <Loader />
      ) : (
        selectedCustomer?.totalPendingOrders !== 0 && (
          <Grid item xs={8.5}>
            <div className="order-list">
              <CustomerOrderHeaderDetails
                selectedCustomer={selectedCustomer}
                customerDetailsSelect={customerDetailsSelect}
                billClick={billClick}
                setBillClick={setBillClick}
              />

              <CustomerOrderTable
                setSelectedCustomer={setSelectedCustomer}
                handleChange={handleChange}
                customerDetailsSelect={customerDetailsSelect}
                orderItems={orderItems}
                setOrderItems={setOrderItems}
              />
            </div>
          </Grid>
        )
      )}

      {billClick === true && partyGroups.status === null ? (
        <Loader />
      ) : (
        <BillModal
          customerDetailsSelect={customerDetailsSelect}
          setCustomerDetailsSelect={setCustomerDetailsSelect}
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
          billClick={billClick}
          setBillClick={setBillClick}
          orderItems={orderItems}
          setOrderItems={setOrderItems}
          setIsOpenAlert={setIsOpenAlert}
          partyGroups={partyGroups}
          setPartyGroups={setPartyGroups}
        />
      )}
    </>
  );
};
