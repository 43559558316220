import React from "react";
import { InputBase, IconButton, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "../CustomersTab/CustomerPage.css";
import CloseIcon from "@mui/icons-material/Close";

// ============================================|| SEARCH FIELD ||============================================ //

export const SearchField = ({
  searchList,
  setSearchList,
  searchLabel,
  setSelectedData,
}) => {
  const handleSearchChange = (e) => {
    setSearchList(e.target.value);
    setSelectedData && setSelectedData();
  };

  const clearSearch = () => {
    setSearchList("");
    setSelectedData && setSelectedData();
  };

  return (
    <div className="customer-search">
      <InputBase
        className="search-input-field"
        placeholder={searchLabel}
        value={searchList}
        onChange={(e) => handleSearchChange(e)}
        endAdornment={
          searchList && (
            <InputAdornment position="end">
              <IconButton onClick={clearSearch}>
                <CloseIcon className="search-close-icon" />
              </IconButton>
            </InputAdornment>
          )
        }
      />

      <IconButton>
        <SearchIcon />
      </IconButton>
    </div>
  );
};
