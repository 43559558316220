import React from "react";
import { TableHead, TableCell, Typography, TableRow } from "@mui/material";
import { uiTexts } from "../../../Utils/uiTexts";
import { RupeeIconWithLabel } from "../../../CommonComp/RupeeIconWithLabel";

// ===================================================|| CUSTOMER ORDER HEADER ||=================================================== //

export const CustomerOrderListHeader = () => {
  const {
    labels: {
      brandNameText,
      quantityText,
      sNoText,
      itemNameText,
      rateText,
      partialText,
      selectText,
      dateText,
      bagText,
      weightText,
    },
    separators: { leftParenthesisText, rightParenthesisText },
  } = uiTexts;

  // Define header data
  const headers = [
    { label: sNoText },
    { label: dateText },
    { label: itemNameText },
    {
      label: `${quantityText} ${leftParenthesisText}${bagText}${rightParenthesisText}`,
    },
    { label: weightText },
    { label: brandNameText },

    {
      label: (
        <RupeeIconWithLabel
          text={rateText}
          labelClassName="orders-text-header"
        />
      ),
    },
    { label: partialText },
    { label: selectText },
    { label: "" },
  ];

  return (
    <TableHead>
      <TableRow>
        {headers?.map((header, index) => (
          <TableCell key={index} className="customer-list-details-divider">
            <Typography className="orders-text-header">
              {header.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
