import React from "react";
import { Table, TableContainer } from "@mui/material";
import { OrderSelectedDetailsTableHeader } from "./OrderSelectedDetailsTableHeader";
import { OrderSelectedDetailsTableItem } from "./OrderSelectedDetailsTableItem";
import { HeaderDetails } from "../../../CommonComp/HeaderDetails";
import "../../OrderPage.css";

// ===================================================|| ORDER DETAILS TABLE ||=================================================== //

export const OrderSelectedDetailsTable = ({
  dummyOrder,
  setDummyOrder,
  filteredOrders,
  errorState,
  setErrorState,
  isEditBillModal,
  selectedBillData,
  setSelectedBillData,
  selectedBillBag,
}) => {
  const headers = HeaderDetails({ isTransportDetails: true });

  return (
    <div className="order-details-modal-table">
      <TableContainer>
        <Table>
          <OrderSelectedDetailsTableHeader headers={headers} />

          <OrderSelectedDetailsTableItem
            dummyOrder={dummyOrder}
            setDummyOrder={setDummyOrder}
            filteredOrders={filteredOrders}
            errorState={errorState}
            setErrorState={setErrorState}
            isEditBillModal={isEditBillModal}
            selectedBillData={selectedBillData}
            setSelectedBillData={setSelectedBillData}
            selectedBillBag={selectedBillBag}
          />
        </Table>
      </TableContainer>
    </div>
  );
};
