import { React, useState } from "react";
import Typography from "@mui/material/Typography";
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { uiTexts } from "../Utils/uiTexts";
import "./Login.css";

// ===================================================|| TEXT FIELDS ||=================================================== //

export const LoginTextFields = ({
  setLoginError,
  setUserName,
  setPassword,
}) => {
  const {
    labels: { userNameText, passwordText },
    placeHolderTexts: { enterUserNameText, enterPasswordText },
  } = uiTexts;

  const [showPassword, setShowPassword] = useState(false);

  //Function to handle username
  const handleUserName = (event) => {
    setLoginError("");
    setUserName(event.target.value);
  };

  //Function to handle password
  const handlePassword = (event) => {
    setLoginError("");
    setPassword(event.target.value);
  };

  //Function used to handle show/hide Password
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="label-container">
        <Typography className="label-text">{userNameText}</Typography>
      </div>

      <OutlinedInput
        margin="normal"
        required
        id="username"
        name="username"
        placeholder={enterUserNameText}
        className="login-textbox"
        onChange={handleUserName}
      />

      <div className="label-container">
        <Typography className="label-text">{passwordText}</Typography>
      </div>

      <OutlinedInput
        margin="normal"
        type={showPassword ? "text" : "password"}
        required
        name="password"
        placeholder={enterPasswordText}
        id="password"
        className="login-textbox"
        onChange={handlePassword}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={handleTogglePassword}>
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        }
      />
    </>
  );
};
