let API_URL = "";
let WEB_URL = window.location.hostname;
if (WEB_URL === "localhost") {
  API_URL = "https://salesdevelopapi.campusplanner.in/api/v1/";
} else if (WEB_URL === "salesanalysis.campusplanner.in") {
  API_URL = "https://salesdevelopapi.campusplanner.in/api/v1/";
} else if (WEB_URL === "sales.campusplanner.in") {
  API_URL = "https://salesapi.campusplanner.in/api/v1/";
}

export default API_URL;
