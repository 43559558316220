import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { uiTexts } from "../Utils/uiTexts";
import { getUserType } from "../Utils/common";
import OrderPage from "../OrdersTab/OrderPage";
import PaymentPage from "../PaymentTab/PaymentPage";
import EditBills from "../EditBills/EditBills";
import WarningPage from "../Warning/WarningPage";

// ==============================|| ACCESS CONTROL ||============================== //

const AccessControl = () => {
  const {
    userTypesText: { salesTeamText, paymentTeamText },
    routingTexts: { orderLowerCaseText, paymentLowerCaseText, editBillsText },
    warnings: { noAccessToThisPageText },
  } = uiTexts;

  const location = useLocation();
  const [activeTab, setActiveTab] = useState("");
  const [accessDenied, setAccessDenied] = useState(false);

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath.includes(orderLowerCaseText)) {
      setActiveTab(orderLowerCaseText);
    } else if (currentPath.includes(paymentLowerCaseText)) {
      setActiveTab(paymentLowerCaseText);
    } else if (currentPath.includes(editBillsText)) {
      setActiveTab(editBillsText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, orderLowerCaseText, paymentLowerCaseText]);

  useEffect(() => {
    const userType = getUserType();
    const currentPath = location.pathname;

    if (
      (userType === salesTeamText &&
        currentPath.includes(paymentLowerCaseText)) ||
      (userType === paymentTeamText &&
        currentPath.includes(orderLowerCaseText)) ||
      (userType === paymentTeamText && currentPath.includes(editBillsText))
    ) {
      setAccessDenied(true);
    } else {
      setAccessDenied(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, salesTeamText, paymentTeamText]);

  if (accessDenied) {
    return <WarningPage warningLabel={noAccessToThisPageText} />;
  }

  switch (activeTab) {
    case orderLowerCaseText:
      return <OrderPage />;
    case paymentLowerCaseText:
      return <PaymentPage />;
    case editBillsText:
      return <EditBills />;
    default:
      return null;
  }
};

export default AccessControl;
