import React from "react";
import { TableBody, TableCell, TableRow } from "@mui/material";
import { formatDate, formatAmount } from "../../../Utils/common";

// ===================================================|| MODAL PAYMENT DETAILS ||=================================================== //

export const BillModalPaymentDetailsItem = ({ filteredBillDetails }) => {
  return (
    <div className="payment-details-modal-scroll">
      <TableBody>
        {filteredBillDetails?.map((bill, billIndex) =>
          bill.paymentDetails?.map((payment, paymentIndex) => (
            <TableRow key={`${billIndex}-${paymentIndex}`}>
              <TableCell className="remove-border-bottom payment-details-modal-info-width">
                {formatDate(payment.date)}
              </TableCell>

              <TableCell className="remove-border-bottom">
                {formatAmount(payment.amount)}
              </TableCell>
            </TableRow>
          )),
        )}
      </TableBody>
    </div>
  );
};
