import React from "react";
import {
  Grid,
  Typography,
  TextField,
  MenuItem,
  IconButton,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../../Report.css";

// ===================================================|| REPORT FIELDS ||=================================================== //

export const ReportFields = ({
  label,
  value,
  onChange,
  select,
  options,
  placeHolder,
  isMultiSelect,
  onDeleteClick,
}) => (
  <>
    <Grid item className="form-field-grid" sx={{ ml: 7 }}>
      <Typography className="bill-modal-text">{label}</Typography>
    </Grid>

    <Grid item sx={{ ml: 5 }}>
      <TextField
        label={placeHolder}
        value={value}
        onChange={onChange}
        select={select}
        style={{ width: "260px" }}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              style: {
                textAlign: "left",
              },
            },
          },
        }}
        InputProps={{
          style: {
            textAlign: "left",
          },
          endAdornment: isMultiSelect && value && (
            <InputAdornment position="end" className="select-field-clear-icon">
              <IconButton onClick={() => onDeleteClick()}>
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        fullWidth
      >
        {options &&
          options?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </TextField>
    </Grid>
  </>
);
