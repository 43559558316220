import { React, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Grid } from "@mui/material";
import pcsLogo from "../Image/pcs.jpg";
import { useNavigate } from "react-router";
import backgroundImage from "../Image/login.jpg";
import {
  getUserName,
  setUserSession,
  setUserType,
  removeUserSession,
} from "../Utils/common";
import { uiTexts } from "../Utils/uiTexts";
import { LoginTextFields } from "./LoginTextFields";
import { createLogin } from "../Services/LoginService";
import "./Login.css";

// ===================================================|| LOGIN ||=================================================== //

const Login = () => {
  const {
    buttonText: { loginText },
    warnings: {
      usernameAndPassWordRequiredText,
      usernameIsRequiredText,
      passwordIsRequiredText,
      noMoreDataText,
      invalidCredentialsText,
      someThingWentWrongText,
    },
    userTypesText: { salesTeamText, paymentTeamText, adminText },
  } = uiTexts;

  const navigate = useNavigate();
  const userSession = getUserName();

  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const [loginError, setLoginError] = useState("");

  useEffect(() => {
    removeUserSession();
    if (userSession === null) navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateCredentials = (userName, password) => {
    if (!userName && !password) {
      return usernameAndPassWordRequiredText;
    } else if (!userName) {
      return usernameIsRequiredText;
    } else if (!password) {
      return passwordIsRequiredText;
    }
    return null;
  };

  const performLogin = async (data) => {
    try {
      const response = await createLogin(data);

      if (response.status === 200) {
        handleSuccessfulLogin(response.data);
      } else {
        setLoginError(noMoreDataText);
      }
    } catch (error) {
      handleLoginError(error);
    }
  };

  const handleSuccessfulLogin = (data) => {
    setUserSession(data.name);
    setUserType(data.userType);

    if (data.userType === salesTeamText || data.userType === adminText) {
      navigate("/orders");
    } else if (data.userType === paymentTeamText) {
      navigate("/payment");
    } else {
      setLoginError(invalidCredentialsText);
    }
  };

  const handleLoginError = (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        setLoginError(invalidCredentialsText);
      } else {
        setLoginError(someThingWentWrongText);
      }
    } else {
      setLoginError(someThingWentWrongText);
    }
  };

  //Function to handle submit
  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationError = validateCredentials(userName, password);

    if (validationError) {
      setLoginError(validationError);
      return;
    }

    const data = {
      userName: userName,
      passWord: password,
    };

    await performLogin(data);
  };

  return (
    <div
      className="background-container"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <Grid container component="main" className="grid-container">
        <Grid item xs={false} sm={4} md={7} className="image-grid-item" />

        <div className="login">
          <Container component="main" maxWidth="sm">
            <Box className="login-container" noValidate autoComplete="off">
              <Grid>
                <img src={pcsLogo} className="pcs-logo-login" alt="logo" />
              </Grid>

              <Box component="form" onSubmit={handleSubmit} noValidate>
                <LoginTextFields
                  setLoginError={setLoginError}
                  setUserName={setUserName}
                  setPassword={setPassword}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className="login-textbox login-button"
                >
                  {loginText}
                </Button>

                {loginError && (
                  <Typography className="error-text">{loginError}</Typography>
                )}
              </Box>
            </Box>
          </Container>
        </div>
      </Grid>
    </div>
  );
};

export default Login;
