import React from "react";
import { IconButton, Typography, Tooltip } from "@mui/material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { useNavigate } from "react-router";
import { uiTexts } from "../Utils/uiTexts";

// ============================================|| BACK ICON ||============================================ //

export const NavigateBackIcon = ({ iconLabel }) => {
  const {
    toolTipText: { backText },
  } = uiTexts;

  const navigate = useNavigate();

  return (
    <>
      <Tooltip title={backText}>
        <IconButton
          className="item-list-back-icon-style"
          onClick={() => navigate("/orders")}
        >
          <ArrowCircleLeftIcon />
        </IconButton>
      </Tooltip>

      <Typography className="pending-bags-label">{iconLabel}</Typography>
    </>
  );
};
