import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { TransportDetailsTable } from "../../OrdersTab/MoveToBill Modal/Transport Details/Transport Details Content/TransportDetailsTable";
import { TransportDetailsActions } from "../../OrdersTab/MoveToBill Modal/Transport Details/Transport Details Content/TransportDetailsActions";
import { TransportInputFields } from "../../OrdersTab/MoveToBill Modal/Transport Details/Transport Details Content/TransportInputFields";
import { uiTexts } from "../../Utils/uiTexts";
import {
  calculateTotalQuantity,
  getPendingBagOrQuantity,
  renderEditGatePassData,
} from "../../Utils/common";

// ===================================================|| EDIT TRANSPORT DETAILS ||=================================================== //

export const EditTransportingDetails = ({
  billingCommonProps,
  transportInfo,
}) => {
  const {
    isArrowIcon,
    setIsArrowIcon,
    billUpdateError,
    updateClick,
    setUpdateClick,
  } = billingCommonProps;
  const {
    dummyOrder,
    handleUpdate,
    handleCancel,
    customerResponsibility,
    setCustomerResponsibility,
    selectedTransport,
    setSelectedTransport,
    setGatePassData,
    gatePassData,
    setExpandedRows,
    expandedRows,
    selectedBillData,
  } = transportInfo;

  const {
    labels: { customerResponsibilityText, transportText },
    buttonText: { noText },
  } = uiTexts;

  const [isTotalQuantityMatched, setIsTotalQuantityMatched] = useState(false);

  useEffect(() => {
    const allMatched = gatePassData?.every((order, orderIndex) => {
      const totalQuantity = calculateTotalQuantity(gatePassData, orderIndex);
      const allErrorsCleared = order.gatePassDetails?.every(
        (detail) => detail.quantityError === "" && detail.quantity !== "",
      );

      return (
        totalQuantity === getPendingBagOrQuantity(order) && allErrorsCleared
      );
    });

    setIsTotalQuantityMatched(allMatched);
  }, [gatePassData]);

  const handleFieldChange = (field, value) => {
    switch (field) {
      case customerResponsibilityText: {
        setCustomerResponsibility(value);
        setSelectedTransport("");
        setGatePassData(renderEditGatePassData(selectedBillData));
        break;
      }

      case transportText:
        setSelectedTransport(value);
        break;

      default:
        break;
    }
  };

  const isUpdateDisabled = () => {
    const isCustomerResponsible = customerResponsibility !== "";
    const isCustomerNotResponsible = customerResponsibility === noText;
    const isTransportNotSelected = selectedTransport === "";
    const hasBillUpdateError = billUpdateError !== "";
    const isQuantityNotMatched = !isTotalQuantityMatched;

    if (!isCustomerResponsible) {
      return false;
    }

    if (isCustomerNotResponsible) {
      return (
        isTransportNotSelected || hasBillUpdateError || isQuantityNotMatched
      );
    }

    return hasBillUpdateError || isQuantityNotMatched;
  };

  const updateDisabled = isUpdateDisabled();

  return (
    <div className="bill-modal-scrollable-content">
      <TransportInputFields
        customerResponsibility={customerResponsibility}
        selectedTransport={selectedTransport}
        handleFieldChange={handleFieldChange}
        isEditBillModal={true}
      />

      <TransportDetailsTable
        dummyOrder={dummyOrder}
        handleFieldChange={handleFieldChange}
        customerResponsibility={customerResponsibility}
        setGatePassData={setGatePassData}
        gatePassData={gatePassData}
        setExpandedRows={setExpandedRows}
        expandedRows={expandedRows}
        isEditBillModal={true}
      />

      <TransportDetailsActions
        handleUpdate={handleUpdate}
        isUpdateDisabled={updateDisabled}
        handleCancel={handleCancel}
        updateClick={updateClick}
        setUpdateClick={setUpdateClick}
        isArrowIcon={isArrowIcon}
        setIsArrowIcon={setIsArrowIcon}
      />

      {billUpdateError && (
        <Typography className="modal-error-text">{billUpdateError}</Typography>
      )}
    </div>
  );
};
