import React from "react";
import { Grid, TextField, Stack, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { uiTexts } from "../../../Utils/uiTexts";

// ===================================================|| USER NAME FIELD ||=================================================== //

export const UserNameInput = ({ userName, handleUserName, userNameError }) => {
  const {
    labels: { userNameText, offText },
    placeHolderTexts: { enterUserNameText, enterYourNameText },
  } = uiTexts;

  return (
    <>
      <Grid item xs={2.7}>
        <Stack direction="row" className="payment-bill-modal-labels">
          <AccountCircleIcon />

          <Typography className="payment-bill-modal-text">
            {enterUserNameText}
          </Typography>
        </Stack>
      </Grid>

      <Grid item xs={2.8}>
        <TextField
          label={userNameText}
          placeholder={enterYourNameText}
          fullWidth
          value={userName}
          onChange={handleUserName}
          autoComplete={offText}
          helperText={userNameError}
          error={userNameError}
          FormHelperTextProps={{
            style: { color: "var(--common-error-color)" },
          }}
        />
      </Grid>
    </>
  );
};
