import React from "react";
import {
  Grid,
  Box,
  Popper,
  Grow,
  Paper,
  MenuItem,
  ClickAwayListener,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import {
  removeUserSession,
  getUserName,
  capitalizeFirstLowercaseRest,
} from "../../Utils/common";
import { useNavigate } from "react-router-dom";
import { uiTexts } from "../../Utils/uiTexts";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import "../Header.css";

// ===================================================|| HEADER PROFILE LIST ||=================================================== //

export const HeaderProfileList = ({
  isOpenLogoutMenu,
  setIsOpenLogoutMenu,
  anchorRef,
}) => {
  const {
    headerLabels: { logoutText },
  } = uiTexts;

  const navigate = useNavigate();

  // Function used to handle Logout
  const handleLogout = () => {
    removeUserSession();
    navigate("/");
  };

  const handleLogoutClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setIsOpenLogoutMenu(false);
  };

  return (
    <Box>
      <Popper
        open={isOpenLogoutMenu}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        style={{ zIndex: 1002 }}
        className="header-icons-info-menu"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleLogoutClose}>
                <CardContent className="profile-card">
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <Stack direction="row" spacing={1.2} alignItems="center">
                        <PersonIcon />
                        <Typography>
                          {capitalizeFirstLowercaseRest(getUserName())}
                        </Typography>
                      </Stack>
                    </Grid>

                    <Grid item>
                      <MenuItem onClick={handleLogout}>
                        <Stack
                          direction="row"
                          spacing={1.2}
                          alignItems="center"
                        >
                          <LogoutIcon />
                          <Typography>{logoutText}</Typography>
                        </Stack>
                      </MenuItem>
                    </Grid>
                  </Grid>
                </CardContent>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};
