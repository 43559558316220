import React from "react";
import { Grid, TextField, Stack, Typography } from "@mui/material";
import cashIcon from "../../../Image/cashIcon.png";
import { uiTexts } from "../../../Utils/uiTexts";

// ===================================================|| AMOUNT FIELD ||=================================================== //

export const AmountInput = ({ amount, handleAmount, amountError }) => {
  const {
    labels: { amountUpperText, offText },
    placeHolderTexts: { enterAmontText },
    warnings: { verifyThePendingAmountText },
  } = uiTexts;

  return (
    <>
      <Grid item xs={3}>
        <Stack direction="row" className="payment-bill-modal-labels">
          <img
            src={cashIcon}
            className="payment-bill-modal-cashIcon black-icon"
            alt="logo"
          />
          <Typography className="payment-bill-modal-text">
            {enterAmontText}
          </Typography>
        </Stack>
      </Grid>

      <Grid item xs={3.5}>
        <TextField
          label={amountUpperText}
          placeholder={enterAmontText}
          fullWidth
          value={amount}
          onChange={handleAmount}
          error={amountError}
          helperText={amountError ? verifyThePendingAmountText : ""}
          autoComplete={offText}
        />
      </Grid>
    </>
  );
};
