import React from "react";
import { Grid, Stack, Typography, RadioGroup } from "@mui/material";
import cashIcon from "../../../Image/cashIcon.png";
import { uiTexts } from "../../../Utils/uiTexts";
import { DeductionRadioButton } from "./DeductionRadioButton";
import "../../PaymentPage.css";

// ===================================================|| DEDUCTION FIELD ||=================================================== //

export const DeductionInput = ({ setDeductionType }) => {
  const {
    labels: { deductionText, cashDiscountText, qualityDifferenceText, tdsText },
    deductionValues: {
      cashDiscountUpperCaseText,
      qualityDifferenceUpperCaseText,
    },
  } = uiTexts;

  const handleChange = (event) => {
    setDeductionType(event.target.value);
  };

  return (
    <>
      <Grid item xs={2.7}>
        <Stack direction="row" className="payment-bill-modal-labels">
          <img
            src={cashIcon}
            className="payment-bill-modal-cashIcon black-icon"
            alt="logo"
          />

          <Typography className="payment-bill-modal-text">
            {deductionText}
          </Typography>
        </Stack>
      </Grid>

      <Grid item xs={2.8} className="bill-modal-deduction-input-padding-left">
        <RadioGroup onChange={handleChange}>
          <Stack
            spacing={1}
            className="bill-modal-deduction-input-radio-button"
          >
            <DeductionRadioButton
              value={cashDiscountUpperCaseText}
              label={cashDiscountText}
            />

            <DeductionRadioButton
              value={qualityDifferenceUpperCaseText}
              label={qualityDifferenceText}
            />

            <DeductionRadioButton value={tdsText} label={tdsText} />
          </Stack>
        </RadioGroup>
      </Grid>
    </>
  );
};
