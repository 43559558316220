import { useDispatch } from "react-redux";
import {
  CustomerList,
  ItemList,
  BrandList,
  CategoryList,
  OrderData,
  CustomerPendingOrders,
  FirmNames,
  OrderItemTransactionsData,
  CustomerPendingPayments,
  ItemWiseOrderedData,
  BillsByDateData,
  TransportData,
  DispatchReportData,
  SalesParametersData,
  BillsData,
  PendingBillsData,
} from "../Store/Reducers/data_menu";
import {
  CustomerStatusCode,
  CustomerPendingOrderStatusCode,
  OrderItemTransactionsDataStatusCode,
  CustomerPendingStatusCode,
  ItemWiseOrderedStatusCode,
  CategoryStatusCode,
  BillsByDateStatusCode,
} from "../Store/Reducers/status_menu";
import {
  getAllCustomers,
  getAllItems,
  getAllBrands,
  getAllCategories,
  getAllFirms,
  getBillsByDate,
  getAllTransportData,
  getAllDispatchReportData,
  getAllSalesParametersData,
} from "../Services/DataService";
import {
  getAllCustomerPendingOrders,
  getAllOrderItemTransactions,
  getAllItemWiseOrders,
  getAllBillsData,
  getAllPendingBillsData,
} from "../Services/OrderService";
import { getAllOrders } from "../Services/OrderService";
import { getAllCustomerPendingPayments } from "../Services/PaymentService";

// ============================================|| RETRIEVE FUNCTIONS ||============================================ //

export default function useRetrieveFunctions() {
  const dispatch = useDispatch();

  const retrieveCustomerData = async () => {
    await getAllCustomers().then(({ data, status }) => {
      dispatch(CustomerList(data));
      if (status === 200) {
        dispatch(CustomerStatusCode(true));
      }
    });
  };

  const retrieveItems = async () => {
    await getAllItems().then(({ data, status }) => {
      dispatch(ItemList(data));
    });
  };

  const retrieveBrands = async () => {
    await getAllBrands().then(({ data, status }) => {
      dispatch(BrandList(data));
    });
  };

  const retrieveCategories = async () => {
    await getAllCategories().then(({ data, status }) => {
      dispatch(CategoryList(data));
      if (status === 200) {
        dispatch(CategoryStatusCode(true));
      }
    });
  };

  const retrieveOrders = async () => {
    await getAllOrders().then(({ data, status }) => {
      dispatch(OrderData(data));
    });
  };

  const retrieveCustomerPendingOrders = async () => {
    await getAllCustomerPendingOrders().then(({ data, status }) => {
      dispatch(CustomerPendingOrders(data));
      if (status === 200) {
        dispatch(CustomerPendingOrderStatusCode(true));
      }
    });
  };

  const retrieveFirmNames = async () => {
    await getAllFirms().then(({ data, status }) => {
      dispatch(FirmNames(data));
    });
  };

  const retrieveAllOrderItemTransactions = async () => {
    await getAllOrderItemTransactions().then(({ data, status }) => {
      dispatch(OrderItemTransactionsData(data));
      if (status === 200) {
        dispatch(OrderItemTransactionsDataStatusCode(true));
      }
    });
  };

  const retrieveCustomerPendingPayments = async () => {
    await getAllCustomerPendingPayments().then(({ data, status }) => {
      dispatch(CustomerPendingPayments(data));
      if (status === 200) {
        dispatch(CustomerPendingStatusCode(true));
      }
    });
  };

  const retrieveItemWiseOrders = async () => {
    await getAllItemWiseOrders().then(({ data, status }) => {
      dispatch(ItemWiseOrderedData(data));
      if (status === 200) {
        dispatch(ItemWiseOrderedStatusCode(true));
      }
    });
  };

  const retrieveBillsByDate = async (date) => {
    await getBillsByDate(date).then(({ data, status }) => {
      dispatch(BillsByDateData(data));
      if (status === 200) {
        dispatch(BillsByDateStatusCode(true));
      }
    });
  };

  const retrieveTransportData = async () => {
    await getAllTransportData().then(({ data, status }) => {
      dispatch(TransportData(data));
    });
  };

  const retrieveDispatchReportData = async () => {
    await getAllDispatchReportData().then(({ data, status }) => {
      dispatch(DispatchReportData(data));
    });
  };

  const retrieveSalesParametersData = async () => {
    await getAllSalesParametersData().then(({ data, status }) => {
      dispatch(SalesParametersData(data));
    });
  };

  const retrieveAllBillsData = async () => {
    await getAllBillsData().then(({ data, status }) => {
      dispatch(BillsData(data));
    });
  };

  const retrieveAllPendingBillsData = async () => {
    await getAllPendingBillsData().then(({ data, status }) => {
      dispatch(PendingBillsData(data));
    });
  };

  return {
    retrieveCustomerData,
    retrieveItems,
    retrieveBrands,
    retrieveCategories,
    retrieveOrders,
    retrieveCustomerPendingOrders,
    retrieveFirmNames,
    retrieveAllOrderItemTransactions,
    retrieveCustomerPendingPayments,
    retrieveItemWiseOrders,
    retrieveBillsByDate,
    retrieveTransportData,
    retrieveDispatchReportData,
    retrieveSalesParametersData,
    retrieveAllBillsData,
    retrieveAllPendingBillsData,
  };
}
