import React from "react";
import { Grid } from "@mui/material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { BillActionsButtons } from "../BillActionsButtons";
import { BillArrowIcon } from "../../BillArrowIcon";

// ===================================================|| TRANSPORT DETAILS ||=================================================== //

export const TransportDetailsActions = ({
  handleUpdate,
  isUpdateDisabled,
  handleCancel,
  updateClick,
  setUpdateClick,
  isArrowIcon,
  setIsArrowIcon,
}) => {
  return (
    <div className="bill-action-button-container">
      <Grid container className="bill-action-button-style">
        <div>
          <BillArrowIcon
            ArrowCircleIcon={ArrowCircleLeftIcon}
            isArrowIcon={isArrowIcon}
            setIsArrowIcon={setIsArrowIcon}
            iconGridCss="bill-left-icon-style"
          />
        </div>

        <div>
          <BillActionsButtons
            handleUpdate={handleUpdate}
            isUpdateDisabled={isUpdateDisabled}
            handleCancel={handleCancel}
            updateClick={updateClick}
            setUpdateClick={setUpdateClick}
          />
        </div>
      </Grid>
    </div>
  );
};
