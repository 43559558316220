import React from "react";
import { Typography, Radio, FormControlLabel } from "@mui/material";
import "../../PaymentPage.css";

// ===================================================|| DEDUCTION RADIO BUTTON ||=================================================== //

export const DeductionRadioButton = ({ value, label }) => (
  <FormControlLabel
    control={<Radio value={value} size="small" />}
    label={
      <Typography className="bill-modal-deduction-input-radio-button-label">
        {label}
      </Typography>
    }
  />
);
