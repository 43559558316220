import React from "react";
import { Grid, Typography } from "@mui/material";
import { uiTexts } from "../Utils/uiTexts";
import "./Footer.css";

// ==============================|| FOOTER ||============================== //

export const Footer = () => {
  const {
    footerLabels: { footerText },
  } = uiTexts;

  return (
    <>
      <Grid className="footer footer-layout">
        <Typography className="footer-text">{footerText}</Typography>
      </Grid>
    </>
  );
};
