import React, { useState } from "react";
import { Grid, Divider } from "@mui/material";
import { PaymentDetails } from "./PendingCustomerBillsDetails/PaymentDetails";
import { PaymentListHeader } from "./PendingPayment/PaymemtListHeader";
import { PaymentListDetails } from "./PendingPayment/PaymentListDetails";
import { getCustomerPendingPaymentsById } from "../Services/PaymentService";
import { SuccessAlert } from "../AlertDisplay/SuccessAlert";
import { uiTexts } from "../Utils/uiTexts";
import { useDispatch } from "react-redux";
import { SelectedBillDetails } from "../Store/Reducers/data_menu";
import "./PaymentPage.css";

// ===================================================|| PAYMENT PAGE DETAILS ||=================================================== //

export const PaymentPageDetails = ({
  filteredPayments,
  setSelectedPayment,
  selectedPayment,
}) => {
  const {
    alertLabels: { paymentWasMadeSuccessfullyText },
  } = uiTexts;

  const dispatch = useDispatch();

  const [selectedBill, setSelectedBill] = useState([]);
  const [selectedPaymentDetails, setSelectedPaymentDetails] = useState();
  const [filteredBillDetails, setFilteredBillDetails] = useState([]);
  const [isOpenAlert, setIsOpenAlert] = useState(false);

  //Function to handle payment clicked
  const handlePayment = async (payment) => {
    setFilteredBillDetails([]);
    setSelectedBill([]);
    setSelectedPaymentDetails([]);
    dispatch(SelectedBillDetails([]));

    if (payment.paidAmount !== 0) {
      try {
        const result = await getCustomerPendingPaymentsById(payment.partyId);
        setSelectedPaymentDetails(result.data);
      } catch (error) {
        console.error("Error handling payment:", error);
      }
    }
    setSelectedPayment(payment);
  };

  return (
    <Grid container className="order-payment-page-area payment-list">
      <Grid item xs={6} className="payment-grid">
        <PaymentListHeader filteredPayments={filteredPayments} />

        <Divider className="customer-list-header-divider" />

        <div className="list-container payment-tab-height">
          <PaymentListDetails
            selectedPayment={selectedPayment}
            handlePayment={handlePayment}
            filteredPayments={filteredPayments}
          />
        </div>
      </Grid>

      {selectedPayment && (
        <PaymentDetails
          selectedPayment={selectedPayment}
          selectedBill={selectedBill}
          setSelectedBill={setSelectedBill}
          selectedPaymentDetails={selectedPaymentDetails}
          setSelectedPaymentDetails={setSelectedPaymentDetails}
          filteredBillDetails={filteredBillDetails}
          setFilteredBillDetails={setFilteredBillDetails}
          setSelectedPayment={setSelectedPayment}
          setIsOpenAlert={setIsOpenAlert}
        />
      )}

      <SuccessAlert
        isOpenAlert={isOpenAlert}
        setIsOpenAlert={setIsOpenAlert}
        alertText={paymentWasMadeSuccessfullyText}
      />
    </Grid>
  );
};
