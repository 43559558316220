// This file will be used for storing all the text and labels.
export const uiTexts = Object.freeze({
  labels: {
    userNameText: "Username",
    passwordText: "Password",
    categoryText: "Category",
    brandText: "Brand",
    itemsText: "Items",
    customersText: "Customers",
    rsText: "Rs.",
    kgText: "Kg.",
    amountText: "amount",
    amountUpperText: "Amount",
    totalText: "Total",
    paidText: "Paid",
    pendingText: "Pending",
    dateText: "Date",
    itemNameText: "Item name",
    netText: "Net",
    outStandingText: "Outstanding",
    firmNameText: "Firm name",
    paymentText: "Payment",
    ordersText: "Orders",
    quantityText: "Quantity",
    sNoText: "S.No",
    partyText: "Party",
    partialText: "Partial",
    selectText: "Select",
    remarksText: "Remarks",
    billText: "Bill",
    orderedDateText: "Ordered date",
    dispatchedDateText: "Dispatched date",
    customerNameText: "Customer name",
    pendingOrdersText: "Pending orders",
    pendingAmountText: "Pending amount",
    numberOfItemsOrderedText: "Number of items ordered",
    billNumberText: "Bill number",
    yourCommentsText: "Your comments",
    taxText: "Tax",
    brandNameText: "Brand name",
    brokerNameText: "Broker name",
    orderedBagsText: "Ordered bags",
    dispatchedBagsText: "Dispatched bags",
    areaText: "Area",
    addresstext: "Address",
    contactNumberText: "Contact number",
    gstNumberText: "GST number",
    panNumberText: "PAN number",
    unitText: "Unit",
    rateText: "Rate (100 Kg's)",
    bagText: "Bag",
    billedDateText: "Billed date",
    offText: "off",
    unKnownText: "Unknown",
    unKnownAreaText: "Unknown Area",
    ratetext: "rate",
    rateUpperText: "Rate",
    pendingBagText: "pendingBag",
    taxAmountText: "taxAmount",
    weightText: "Weight(Kg.)",
    deductionText: "Deduction",
    cashDiscountText: "Cash Discount",
    qualityDifferenceText: "Quality Difference",
    tdsText: "TDS",
    typeText: "Type",
    unitInKgText: "Unit(Kg.)",
    lorryNameText: "Lorry name",
    transportNameText: "Transport name",
    lorryText: "lorry",
    transportText: "transport",
    fareText: "Fare",
    netFareText: "Net fare",
    deliveryRateText: "Delivery rate",
    customerResponsibilityText: "Customer responsibility",
    gatePassNumberText: "Gatepass number",
    vehicleNumberText: "Vehicle number",
    quantityLowerCaseText: "quantity",
    vehicleNumberLowerCaseText: "vehicleNumber",
    advancePaymentRequiredText: "Advance payment required",
    deliveryDateIsApplicableText: "Delivery date is applicable",
    discountRateIsApplicableText: "Discount rate is applicable",
    dotPdfText: ".pdf",
    editBillsText: "Edit bills",
    bagLowerCaseText: "bag",
    pendingBillsText: "Pending bills",
    confirmationText: "Confirmation",
    areYouSureYouWantToDeleteThisOrderText:
      "Are you sure you want to delete this order ?",
    deliveryRateLowerCaseText: "deliveryRate",
  },

  keyLabels: {
    totalPendingOrdersKey: "totalPendingOrders",
    totalPendingAmountKey: "totalPendingAmount",
    firmNameKey: "firmName",
    categoryNameKey: "categoryName",
  },

  warnings: {
    usernameAndPassWordRequiredText: "UserName and password is required",
    usernameIsRequiredText: "Username is required",
    passwordIsRequiredText: "Password is required",
    invalidCredentialsText: "Invalid credentials",
    noMoreDataText: "No more data",
    someThingWentWrongText: "Something went wrong",
    noDetailsAvailableForThisCustomerText:
      "No details available for this customer.",
    noDetailsAvailableForThisItemText: "No details available for this Item.",
    inValidValueText: "Invalid value",
    verifyThePendingAmountText: "Verify the pending amount",
    customerNotFoundText: "Customer not found",
    ItemNotFoundText: "Item not found",
    noPendingBagsText: "No pending bags.",
    nobillsForSelectedDateText: "No bills for selected date",
    noMatchingDataWasFoundText: "No matching data was found",
    onlyNumbersAllowedText: "Only numbers allowed",
    verifyTheQuantityText: "Verify the quantity",
    onlyAlphabetsAreAllowedText: "Only alphabets are allowed.",
    noAccessToThisPageText: "No access to this page.",
    pageNotFoundText: "Page not found.",
  },

  buttonText: {
    loginText: "Login",
    moveToBillText: " Move to bill",
    yesText: "Yes",
    updateText: "Update",
    cancelText: "Cancel",
    reportText: "Report",
    downloadText: "Download",
    clearText: "Clear",
    noText: "No",
  },

  separators: {
    hyphenText: "-",
    leftParenthesisText: " (",
    rightParenthesisText: ") ",
    colonText: " : ",
    underscoreText: "_",
  },

  footerLabels: {
    footerText: "PCS AGRI TECH - Virudhunagar 626 001",
  },

  headerLabels: {
    agriTechUpperCaseText: "AGRI TECH",
    pendingBagsText: "Pending bags",
    logoutText: "Logout",
  },

  placeHolderTexts: {
    enterUserNameText: "Enter username",
    enterPasswordText: "Enter password",
    enterAmontText: "Enter amount",
    chooseDateAndTimeText: "Choose date and time",
    enterYourNameText: "Enter your name",
    enterBillNumberText: "Enter bill number",
    chooseFirmText: "Choose firm",
    addYourCommentsText: "Add your comments",
    dateTimeInputFormat: "DD-MM-YYYY    HH:mm:ss",
    searchCustomerText: "Search customer",
    searchItemText: "Search item",
    chooseReportText: "Choose report",
    chooseDateText: "Choose date",
    chooseCategoryText: "Choose category",
    dateInputFormat: "DD-MM-YYYY",
    selectParty: "Select party",
    ifNecessaryText: "if necessary",
    enterLorryName: "Enter lorry name",
    chooseTransportText: "Choose transport",
    enterQuantityText: "Enter quantity",
    enterVehicleNumberText: "Enter vehicle number",
  },

  alertLabels: {
    orderPlacedSuccessfullyText: "Order placed successfully !",
    paymentWasMadeSuccessfullyText: "Payment was made successfully !",
    clickAwayText: "clickaway",
    infoText: "Info",
    customersHaveMissingInformationText: "customers have missing information.",
    missingDetailsText: "Missing details",
    isMissingText: " is missing.",
  },

  deductionValues: {
    normalUpperCaseText: "NORMAL",
    cashDiscountUpperCaseText: "CASH_DISCOUNT",
    qualityDifferenceUpperCaseText: "QUALITY_DIFFERENCE",
  },

  toolTipText: {
    paymentDetailsText: "Payment Details",
    moreInfoText: "More Info",
    backText: "Back",
    profileText: "Profile",
  },

  userTypesText: {
    adminText: "ADMIN",
    salesTeamText: "SALES_TEAM",
    paymentTeamText: "PAYMENT_TEAM",
    orderTeamText: "ORDER_TEAM",
  },

  routingTexts: {
    orderLowerCaseText: "orders",
    paymentLowerCaseText: "payment",
    editBillsText: "editBills",
  },
});
