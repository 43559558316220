import React, { useEffect, useState } from "react";
import { Loader } from "../Loader/Loader";
import { Header } from "../Header/Header";
import useRetrieveFunctions from "../CustomHooks/useRetrieveFunctions";
import { useSelector } from "react-redux";
import { AccordionDisplay } from "./Accordion/AccordionDisplay";
import { Footer } from "../Footer/Footer";
import { CustomerWarnings } from "./CustomerWarnings";
import "./CustomerPage.css";

// ============================================|| CUSTOMER PAGE ||============================================ //

const CustomerPage = () => {
  const { customerList } = useSelector((state) => state.data_menu);
  const { customerStatusCode } = useSelector((state) => state.status_menu);

  const { retrieveCustomerData } = useRetrieveFunctions();

  const [countMissingData, setCountMissingData] = useState(0);

  useEffect(() => {
    retrieveCustomerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const missingDataCount = customerList?.filter((party) => {
      return (
        !party.partyName?.trim() ||
        !party.areaName?.trim() ||
        !party.gstNo?.trim() ||
        !party.address?.trim() ||
        !party.contactNo?.trim() ||
        !party.panNo?.trim()
      );
    }).length;

    setCountMissingData(missingDataCount);
  }, [customerList]);

  return (
    <>
      <Header />

      {!customerStatusCode ? (
        <Loader />
      ) : (
        <>
          {countMissingData && (
            <CustomerWarnings countMissingData={countMissingData} />
          )}

          <AccordionDisplay />
        </>
      )}

      <Footer />
    </>
  );
};

export default CustomerPage;
