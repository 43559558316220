import React from "react";
import { Table } from "@mui/material";
import { AccordionDetailsSummaryTableHeader } from "./AccordionDetailsSummaryTableHeader";
import { AccordionDetailsSummaryTableItem } from "./AccordionDetailsSummaryTableItem";

// ============================================|| ACCORDION DETAILS SUMMARY ||============================================ //


export const AccordionDetailsSummary = ({ customerDetailsByIndex }) => {
  return (
    <Table>
      <AccordionDetailsSummaryTableHeader />

      <AccordionDetailsSummaryTableItem
        customerDetailsByIndex={customerDetailsByIndex}
      />
    </Table>
  );
};
