import React from "react";
import { Button, Grid } from "@mui/material";
import { uiTexts } from "../../../Utils/uiTexts";
import "../../OrderPage.css";

// ===================================================|| BILL ACTION BUTTON ||=================================================== //

export const BillActionsButtons = ({
  handleCancel,
  handleUpdate,
  isUpdateDisabled,
  updateClick,
  setUpdateClick,
}) => {
  const {
    buttonText: { updateText, cancelText },
  } = uiTexts;

  const ActionButton = ({ onClick, disabled, text, buttonStyle }) => (
    <Button className={buttonStyle} onClick={onClick} disabled={disabled}>
      {text}
    </Button>
  );

  return (
    <Grid container spacing={2}>
      <Grid item>
        <ActionButton
          onClick={handleCancel}
          text={cancelText}
          disabled={updateClick === true}
          buttonStyle={
            updateClick === true
              ? "bill-modal-button bill-modal-cancel-button-disabled"
              : "bill-modal-button bill-modal-cancel-button"
          }
        />
      </Grid>

      <Grid item>
        <ActionButton
          onClick={() => {
            setUpdateClick(true);
            handleUpdate();
          }}
          text={updateText}
          buttonStyle={
            isUpdateDisabled
              ? "bill-modal-button bill-modal-update-button-disabled-bg-color"
              : "bill-modal-button bill-modal-update-button-bg-color"
          }
          disabled={isUpdateDisabled}
        />
      </Grid>
    </Grid>
  );
};
