import React from "react";
import { Button, Grid, ListItem, Typography } from "@mui/material";
import storeIcon from "../../Image/storeIcon.png";
import { formatAmount } from "../../Utils/common";

// ===================================================|| PAYMENT LIST DETAILS ||=================================================== //

export const PaymentListDetails = ({
  selectedPayment,
  handlePayment,
  filteredPayments,
}) => {
  return (
    <>
      {filteredPayments?.map(
        (payment, index) =>
          payment.totalPendingAmount !== 0 && (
            <Button
              className={
                selectedPayment && payment.partyId === selectedPayment.partyId
                  ? "orders-payment-content--button orders-payment-content--button--selected"
                  : "orders-payment-content--button"
              }
              key={index + 1}
              onClick={() => {
                handlePayment(payment);
              }}
            >
              <ListItem>
                <Grid container>
                  <Grid item xs={10}>
                    <Typography className="customer-details-list-text">
                      <img
                        src={storeIcon}
                        className="storeIcon-style"
                        alt="logo"
                      />
                      {payment.partyName}
                    </Typography>
                  </Grid>

                  <Grid item xs={2}>
                    <Typography className="customer-details-list-text">
                      {formatAmount(payment.totalPendingAmount)}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            </Button>
          ),
      )}
    </>
  );
};
