import React, { useState } from "react";
import { Grid, Divider } from "@mui/material";
import { OrderListHeader } from "../OrdersTab/PendingOrders/OrderListHeader";
import { OrderListDetails } from "./PendingOrders/OrderListDetails";
import { CustomerOrder } from "./PendingCustomerOrdersDetails/CustomerOrder";
import { getOrderItemTransactionById } from "../Services/OrderService";
import { SuccessAlert } from "../AlertDisplay/SuccessAlert";
import { uiTexts } from "../Utils/uiTexts";
import "./OrderPage.css";

// ===================================================|| ORDERS PAGE DETAILS ||=================================================== //

export const OrderPageDetails = ({
  filteredCustomers,
  setSelectedCustomer,
  selectedCustomer,
}) => {
  const {
    alertLabels: { orderPlacedSuccessfullyText },
  } = uiTexts;

  const [orderItems, setOrderItems] = useState([]);
  const [isOpenAlert, setIsOpenAlert] = useState(false);

  const handleCustomerSelect = async (orders) => {
    setOrderItems([]);
    setSelectedCustomer(orders);

    const orderItemsPromises = orders?.orderItemTransactionIds.map(
      async (id) => {
        const result = await getOrderItemTransactionById(id);
        return result.data;
      },
    );

    const results = await Promise.all(orderItemsPromises);
    setOrderItems(results);
  };

  return (
    <Grid container className="order-payment-page-area order-list">
      <Grid item xs={3.5} className="order-grid">
        <OrderListHeader filteredCustomers={filteredCustomers} />

        <Divider className="customer-list-header-divider" />

        <div className="list-container order-tab-height">
          <OrderListDetails
            selectedCustomer={selectedCustomer}
            handleCustomerSelect={handleCustomerSelect}
            filteredCustomers={filteredCustomers}
          />
        </div>
      </Grid>

      {selectedCustomer && (
        <CustomerOrder
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
          orderItems={orderItems}
          setOrderItems={setOrderItems}
          setIsOpenAlert={setIsOpenAlert}
        />
      )}

      <SuccessAlert
        isOpenAlert={isOpenAlert}
        setIsOpenAlert={setIsOpenAlert}
        alertText={orderPlacedSuccessfullyText}
      />
    </Grid>
  );
};
