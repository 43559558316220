import React, { useEffect } from "react";
import { Header } from "../Header/Header";
import { NavigateBackIcon } from "../CommonComp/NavigateBackIcon";
import { ReportContents } from "./ReportPage Details/ReportContents";
import useRetrieveFunctions from "../CustomHooks/useRetrieveFunctions";
import { useSelector } from "react-redux";
import { Loader } from "../Loader/Loader";
import { uiTexts } from "../Utils/uiTexts";
import "./Report.css";

// ============================================|| REPORT ||============================================ //

function Report() {
  const {
    buttonText: { reportText },
  } = uiTexts;

  const { retrieveCategories } = useRetrieveFunctions();

  const { categoryStatusCode } = useSelector((state) => state.status_menu);

  useEffect(() => {
    retrieveCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />
      {!categoryStatusCode ? (
        <Loader />
      ) : (
        <>
          <div className="pending-bags-page-style item-list-backIcon-container">
            <NavigateBackIcon iconLabel={reportText} />
          </div>

          <ReportContents />
        </>
      )}
    </>
  );
}

export default Report;
