import React from "react";
import { Table, TableContainer } from "@mui/material";
import { OrderSelectedDetailsTableHeader } from "../../OrderSelectedTable/OrderSelectedDetailsTableHeader";
import { TransportDetailsTableItem } from "../TransportDetailsTableItem";
import { HeaderDetails } from "../../../../CommonComp/HeaderDetails";

// ===================================================|| TRANSPORT DETAILS ||=================================================== //

export const TransportDetailsTable = ({
  dummyOrder,
  handleFieldChange,
  customerResponsibility,
  setGatePassData,
  gatePassData,
  setExpandedRows,
  expandedRows,
  isEditBillModal,
}) => {
  const headers = HeaderDetails({
    isTransportDetails: false,
    customerResponsibility,
  });

  return (
    <div className="order-details-modal-table bill-modal-transport-table">
      <TableContainer>
        <Table>
          <OrderSelectedDetailsTableHeader headers={headers} />

          <TransportDetailsTableItem
            dummyOrder={dummyOrder}
            handleFieldChange={handleFieldChange}
            customerResponsibility={customerResponsibility}
            setGatePassData={setGatePassData}
            gatePassData={gatePassData}
            setExpandedRows={setExpandedRows}
            expandedRows={expandedRows}
            isEditBillModal={isEditBillModal}
          />
        </Table>
      </TableContainer>
    </div>
  );
};
