import React from "react";
import { Grid } from "@mui/material";
import { HeaderLhs } from "./HeaderLhs";
import { HeaderTabs } from "./HeaderTabs";
import { HeaderRhs } from "./HeaderRHS/HeaderRhs";
import "./Header.css";

// ===================================================|| HEADER ||=================================================== //

export const Header = () => {
  return (
    <Grid
      container
      className="header"
      alignItems="center"
      justifyContent="space-between"
    >
      <HeaderLhs />

      <HeaderTabs />

      <HeaderRhs />
    </Grid>
  );
};
