import React from "react";
import { Typography, Grid } from "@mui/material";
import { uiTexts } from "../Utils/uiTexts";

// ==========================================|| HEADER GRID ||========================================== //

export const HeaderGrid = ({ text, xs, count }) => {
  const {
    separators: { leftParenthesisText, rightParenthesisText },
  } = uiTexts;

  return (
    <Grid item xs={xs}>
      <Typography className="customer-list-header-text">
        {text}
        {leftParenthesisText}
        {count}
        {rightParenthesisText}
      </Typography>
    </Grid>
  );
};
