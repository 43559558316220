import React from "react";
import { Divider, IconButton, Stack, Button } from "@mui/material";
import { uiTexts } from "../../Utils/uiTexts";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { deleteOrderItemById } from "../../Services/OrderService";
import useRetrieveFunctions from "../../CustomHooks/useRetrieveFunctions";

// =========================================|| Confirmation Modal ||======================================== //

export const ConfirmationModal = ({
  setIsOpenRemoveConfirmationModal,
  isOpenRemoveConfirmationModal,
  handleCloseConfirmationModal,
  selectTransactionId,
  orderItems,
  setOrderItems,
  setSelectedCustomer,
}) => {
  const {
    buttonText: { noText, yesText },
    labels: { confirmationText, areYouSureYouWantToDeleteThisOrderText },
  } = uiTexts;

  const { retrieveCustomerPendingOrders } = useRetrieveFunctions();

  // Function remove order item
  const removeOrderItem = async (selectTransactionId) => {
    const remainingOrderItems = orderItems?.filter(
      (order) => order.id !== selectTransactionId,
    );
    const itemToDelete = orderItems?.find(
      (order) => order.id === selectTransactionId,
    );

    if (itemToDelete) {
      await deleteOrderItemById(selectTransactionId);
    }

    setOrderItems(remainingOrderItems);

    if (remainingOrderItems?.length === 0) {
      setSelectedCustomer();
    }

    await retrieveCustomerPendingOrders();

    handleCloseConfirmationModal();
  };

  return (
    <div>
      <Dialog
        open={isOpenRemoveConfirmationModal}
        aria-labelledby="responsive-dialog-title"
      >
        <Stack direction="row">
          <DialogTitle
            id="responsive-dialog-title"
            className="confirmation-modal"
          >
            {confirmationText}
          </DialogTitle>

          <IconButton
            aria-label="close"
            onClick={() => handleCloseConfirmationModal()}
            className="confirmation-modal-close-icon-button"
          >
            <CloseIcon />
          </IconButton>
        </Stack>

        <Divider />

        <DialogContent>
          <DialogContentText className="confirmation-modal" align="center">
            {areYouSureYouWantToDeleteThisOrderText}
          </DialogContentText>
        </DialogContent>

        <DialogActions className="confirmation-modal-action-buttons">
          <Button
            onClick={() => removeOrderItem(selectTransactionId)}
            autoFocus
            variant="contained"
            className="confirmation-modal-yes-button"
          >
            {yesText}
          </Button>

          <Button
            onClick={() => handleCloseConfirmationModal()}
            autoFocus
            variant="outlined"
            className="confirmation-modal-no-button"
          >
            {noText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
