import React from "react";
import { Typography, Grid, Stack, Tooltip } from "@mui/material";
import storeIcon from "../../Image/storeIcon.png";
import { uiTexts } from "../../Utils/uiTexts";
import { capitalizeFirstLowercaseRest } from "../../Utils/common";
import "../CustomerPage.css";

//-------------------------------------------|| ACCORDION HEADER || ---------------------------------------------

export const AccordionHeader = ({ customer }) => {
  const {
    alertLabels: { missingDetailsText },
  } = uiTexts;

  const hasMissingData =
    !customer.partyName?.trim() ||
    !customer.areaName?.trim() ||
    !customer.gstNo?.trim() ||
    !customer.address?.trim() ||
    !customer.contactNo?.trim() ||
    !customer.panNo?.trim();

  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Stack direction="row">
          <img src={storeIcon} className="storeIcon-style" alt="logo" />

          <Typography className="customer-party-text">
            {customer.partyName} -{" "}
            {capitalizeFirstLowercaseRest(customer.areaName)}
          </Typography>

          {hasMissingData && (
            <Tooltip title={missingDetailsText}>
              <div className="has-missing-data-dot" />
            </Tooltip>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};
