import React from "react";
import { Stack, Typography } from "@mui/material";
import { uiTexts } from "../../Utils/uiTexts";
import "../PaymentPage.css";

// ===================================================|| PAYMENT HEADER ||=================================================== //

const PendingBillHeader = ({ label, value }) => {
  const {
    separators: { colonText },
  } = uiTexts;
  return (
    <Stack direction="row" className="payment-header-text">
      <Typography className="payment-header-text-label">{label}</Typography>
      <Typography className="colon-style">{colonText}</Typography>
      <Typography className="payment-details-style">{value}</Typography>
    </Stack>
  );
};

export default PendingBillHeader;
