import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  TableContainer,
  Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import { uiTexts } from "../../Utils/uiTexts";
import { Loader } from "../../Loader/Loader";
import { ItemListAccordionSummary } from "./ItemListAccordionSummary/ItemListAccordionSummary";
import { getItemWiseOrderById } from "../../Services/OrderService";
import { ItemListAccordionHeader } from "./ItemAccordionHeader";
import { SearchField } from "../../CommonComp/SearchField";
import { NavigateBackIcon } from "../../CommonComp/NavigateBackIcon";
import "../PendingBags.css";

// ===================================================|| ACCORDION LIST ||=================================================== //

export const AccordionList = () => {
  const { itemWiseOrderedData, orderItemTransactionsData } = useSelector(
    (state) => state.data_menu,
  );

  const {
    warnings: {
      noDetailsAvailableForThisItemText,
      ItemNotFoundText,
      noPendingBagsText,
    },
    placeHolderTexts: { searchItemText },
    headerLabels: { pendingBagsText },
  } = uiTexts;

  const [searchList, setSearchList] = useState("");
  const [openAccordions, setOpenAccordions] = useState([]);
  const [selectedItemDetails, setSelectedItemDetails] = useState([]);
  const [itemListLoader, setItemListLoader] = useState(false);
  const [itemsWithOrderCount, setItemsWithOrderCount] = useState([]);

  useEffect(() => {
    //Store items which have order count
    const filteredItems = itemWiseOrderedData.filter(
      (item) => item.orderCount > 0,
    );
    setItemsWithOrderCount(filteredItems);
  }, [itemWiseOrderedData]);

  //Filter items based on search
  const filteredItems = itemsWithOrderCount.filter((item) =>
    item.itemName.toLowerCase().includes(searchList.toLowerCase()),
  );

  const isEmptyData =
    itemWiseOrderedData.length !== 0 &&
    (filteredItems?.length === 0 || filteredItems?.length === undefined);

  useEffect(() => {
    //Close all accordion if seachlist is changed
    setOpenAccordions([]);
  }, [searchList]);

  const handleAccordionExpand = async (id, index, isExpanded) => {
    if (isExpanded) {
      setItemListLoader(true);
      try {
        const { data: itemDetails } = await getItemWiseOrderById(id);
        const orderItemTransactionIds = itemDetails?.map(
          (item) => item.orderItemTransactionId,
        );

        const itemOrderDetails = orderItemTransactionsData?.filter((filRes) =>
          orderItemTransactionIds.includes(filRes.id),
        );

        setSelectedItemDetails((prev) => ({
          ...prev,
          [index]: { itemDetails, itemOrderDetails },
        }));
      } catch (error) {
        console.error("Error fetching item details:", error);
      } finally {
        setItemListLoader(false);
      }
    }

    setOpenAccordions((prevOpenAccordions) =>
      isExpanded
        ? [...prevOpenAccordions, index]
        : prevOpenAccordions.filter(
            (accordionIndex) => accordionIndex !== index,
          ),
    );
  };

  return (
    <>
      {itemListLoader && <Loader />}

      {/* Display accordion only if only have order */}
      {itemsWithOrderCount?.length === 0 ? (
        <div className="no-bags-error-wrapper">
          <Typography className="no-bags-error">{noPendingBagsText}</Typography>
        </div>
      ) : (
        <>
          <div className="pending-bags-page-style item-list-backIcon-container">
            <NavigateBackIcon iconLabel={pendingBagsText} />
          </div>

          <div className="pending-bags-page-style item-search-container">
            <SearchField
              searchList={searchList}
              setSearchList={setSearchList}
              searchLabel={searchItemText}
            />
          </div>

          <div className="item-list-container">
            {filteredItems
              ?.sort((a, b) => b.orderCount - a.orderCount)
              ?.map(
                (items, index) =>
                  items.orderCount !== 0 && (
                    <Accordion
                      key={index}
                      className="item-list-accordion"
                      expanded={openAccordions.includes(index)}
                      onChange={(event, isExpanded) =>
                        handleAccordionExpand(items.itemId, index, isExpanded)
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index + 1}-content`}
                        id={`panel${index + 1}-header`}
                      >
                        <ItemListAccordionHeader items={items} />
                      </AccordionSummary>

                      <AccordionDetails className="item-accordion-container">
                        <TableContainer component={Paper} sx={{ mt: 2 }}>
                          {!selectedItemDetails[index] ||
                          !selectedItemDetails[index].itemOrderDetails ||
                          !Array.isArray(
                            selectedItemDetails[index].itemOrderDetails,
                          ) ||
                          selectedItemDetails[index].itemOrderDetails.length ===
                            0 ? (
                            <Typography className="no-details-warning-text">
                              {noDetailsAvailableForThisItemText}
                            </Typography>
                          ) : (
                            <ItemListAccordionSummary
                              itemDetailsByIndex={
                                selectedItemDetails[index].itemDetails
                              }
                              itemOrderDetailsByIndex={
                                selectedItemDetails[index].itemOrderDetails
                              }
                            />
                          )}
                        </TableContainer>
                      </AccordionDetails>
                    </Accordion>
                  ),
              )}

            {isEmptyData && (
              <Typography className="error-record-not-found">
                {ItemNotFoundText}
              </Typography>
            )}
          </div>
        </>
      )}
    </>
  );
};
