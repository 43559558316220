import React from "react";
import { Grid } from "@mui/material";
import { uiTexts } from "../../Utils/uiTexts";
import { calculateTotal } from "../../Utils/common";
import { HeaderGrid } from "../../CommonComp/HeaderGrid";

// ===================================================|| ORDER LIST HEADER ||=================================================== //

export const OrderListHeader = ({ filteredCustomers }) => {
  const {
    labels: { customerNameText, pendingOrdersText },
    keyLabels: { totalPendingOrdersKey },
  } = uiTexts;

  return (
    <Grid
      container
      justifyContent="space-between"
      className="customer-list-header"
    >
      <HeaderGrid
        text={customerNameText}
        xs={9}
        count={filteredCustomers?.length}
      />
      <HeaderGrid
        text={pendingOrdersText}
        xs={3}
        count={calculateTotal(filteredCustomers, totalPendingOrdersKey)}
      />
    </Grid>
  );
};
