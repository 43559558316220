import React from "react";
import { TableBody, TableCell, TableRow } from "@mui/material";
import {
  formatDate,
  handleUnknown,
  extractNumericValue,
} from "../../../Utils/common";
import { uiTexts } from "../../../Utils/uiTexts";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { GatePassDetails } from "./GatePass/GatePassDetails";

// =======================================|| TRANSPORT DETAILS TABLE ITEM ||========================================== //

export const TransportDetailsTableItem = ({
  dummyOrder,
  handleFieldChange,
  customerResponsibility,
  setGatePassData,
  gatePassData,
  setExpandedRows,
  expandedRows,
  isEditBillModal,
}) => {
  const {
    labels: { unKnownText },
    separators: { hyphenText },
    buttonText: { noText },
  } = uiTexts;

  const toggleRow = (index) => {
    if (expandedRows.includes(index)) {
      setExpandedRows(expandedRows.filter((row) => row !== index));
    } else {
      setExpandedRows([...expandedRows, index]);
    }
  };

  const renderTableCell = (content) => (
    <TableCell className="bill-modal-font expand-transport-details-table-item">
      {content}
    </TableCell>
  );

  return (
    <TableBody>
      {dummyOrder?.map((order, index) => {
        const isExpanded = expandedRows.includes(index);

        return (
          <React.Fragment key={index}>
            <TableRow
              className="transport-details-table-item"
              onClick={() => toggleRow(index)}
            >
              {renderTableCell(index + 1)}

              {renderTableCell(formatDate(order.orderDate))}

              {renderTableCell(
                `${order.itemName} ${
                  order.unitListName !== unKnownText ? order.unitListName : ""
                } ${
                  order.cleanCategoryName !== unKnownText
                    ? order.cleanCategoryName
                    : ""
                }`,
              )}

              {renderTableCell(
                order.quantityInKg === null
                  ? isEditBillModal
                    ? order.bag
                    : order.pendingBag
                  : order.quantityInKg,
              )}

              {renderTableCell(
                order.quantityInKg === null
                  ? isEditBillModal
                    ? order.bag * extractNumericValue(order.unitListName)
                    : order.pendingBag * extractNumericValue(order.unitListName)
                  : order.quantityInKg,
              )}

              {renderTableCell(
                handleUnknown(unKnownText, order.brandName, hyphenText),
              )}

              {customerResponsibility === noText && (
                <>
                  {renderTableCell("-")}

                  {renderTableCell("-")}
                </>
              )}

              <TableCell className="expand-transport-details-table-item">
                {isExpanded ? (
                  <ExpandLessIcon className={`bill-modal-expand-icon`} />
                ) : (
                  <ExpandMoreIcon className={`bill-modal-expand-icon`} />
                )}
              </TableCell>
            </TableRow>

            {/* Row for expanded content */}
            {isExpanded && (
              <GatePassDetails
                gatePassData={gatePassData}
                setGatePassData={setGatePassData}
                index={index}
              />
            )}
          </React.Fragment>
        );
      })}
    </TableBody>
  );
};
