import React from "react";
import { Table } from "@mui/material";
import { ItemListAccordionTableHeader } from "./ItemListAccordionTableHeader";
import { ItemListAccordionTableItem } from "./ItemListAccordionTableItem";

// ============================================|| ACCORDION DETAILS SUMMARY ||============================================ //

export const ItemListAccordionSummary = ({
  itemDetailsByIndex,
  itemOrderDetailsByIndex,
}) => {
  return (
    <Table>
      <ItemListAccordionTableHeader />

      <ItemListAccordionTableItem
        itemDetailsByIndex={itemDetailsByIndex}
        itemOrderDetailsByIndex={itemOrderDetailsByIndex}
      />
    </Table>
  );
};
