import React from "react";
import { Grid, Typography, TextField, Autocomplete } from "@mui/material";
import { uiTexts } from "../../../Utils/uiTexts";

// ===================================================|| MODIFY PARTY ||=================================================== //

export const ModifyParty = ({
  modifiedParty,
  setModifiedParty,
  partyGroups,
  setPartyGroups,
}) => {
  const {
    placeHolderTexts: { selectParty, ifNecessaryText },
    separators: { leftParenthesisText, rightParenthesisText },
  } = uiTexts;

  return (
    <Grid container>
      <Grid item xs={2.5} className="form-field-grid modify-party-text">
        <Typography className="bill-modal-text">
          {selectParty}
          {leftParenthesisText}
          {ifNecessaryText}
          {rightParenthesisText}
        </Typography>
      </Grid>

      <Autocomplete
        xs={3}
        id="party-select-demo"
        className="modify-party-field"
        options={partyGroups.data}
        value={modifiedParty}
        getOptionLabel={(option) => option.partyName}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(event, newValue) => setModifiedParty(newValue)}
        renderInput={(params) => <TextField {...params} label={selectParty} />}
      />
    </Grid>
  );
};
