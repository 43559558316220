import React from "react";
import { Table, TableContainer } from "@mui/material";
import { CustomerOrderListHeader } from "./CustomerOrderListHeader";
import { CustomerOrderListDetails } from "./CustomerOrderListDetails";

// ===================================================|| CUSTOMER ORDER HEADER ||=================================================== //

export const CustomerOrderTable = ({
  setSelectedCustomer,
  handleChange,
  customerDetailsSelect,
  orderItems,
  setOrderItems,
}) => {
  return (
    <TableContainer
      className="customer-order-details-header list-container order-details-page-height"
      sx={{ width: "auto", overflowX: "auto" }}
    >
      <Table stickyHeader>
        <CustomerOrderListHeader />

        <CustomerOrderListDetails
          setSelectedCustomer={setSelectedCustomer}
          handleChange={handleChange}
          customerDetailsSelect={customerDetailsSelect}
          orderItems={orderItems}
          setOrderItems={setOrderItems}
        />
      </Table>
    </TableContainer>
  );
};

export default CustomerOrderTable;
