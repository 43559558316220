import React, { useState, useEffect } from "react";
import { Typography, Grid } from "@mui/material";
import { uiTexts } from "../../../Utils/uiTexts";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { BillInputFields } from "./BillInputFields";
import { OrderSelectedDetailsTable } from "../OrderSelectedTable/OrderSelectedDetailsTable";
import {
  renderDateTime,
  isEmptyField,
  isValidAlphabeticalInput,
} from "../../../Utils/common";
import { BillArrowIcon } from "../BillArrowIcon";
import { ModifyParty } from "./ModifyParty";

// ===================================================|| BILLING DETAILS ||=================================================== //

export const BillingDetails = ({
  billingInfo,
  userInfo,
  appState,
  billingCommonProps,
  partyGroups,
  setPartyGroups,
}) => {
  const {
    filteredOrders,
    dummyOrder,
    setDummyOrder,
    billNumber,
    setBillNumber,
    selectedFirm,
    setSelectedFirm,
  } = billingInfo;
  const {
    userName,
    setUserName,
    userComments,
    setUserComments,
    setUserNameError,
    userNameError,
  } = userInfo;
  const {
    selectedDate,
    setSelectedDate,
    updateDate,
    setUpdateDate,
    modifiedParty,
    setModifiedParty,
  } = appState;
  const {
    isArrowIcon,
    setIsArrowIcon,
    billUpdateError,
    setBillUpdateError,
    setUpdateClick,
  } = billingCommonProps;

  const {
    labels: { billNumberText, firmNameText, userNameText, yourCommentsText },
    warnings: { onlyAlphabetsAreAllowedText },
  } = uiTexts;

  useEffect(() => {
    const intervalId = renderDateTime(setSelectedDate, updateDate);

    return () => {
      if (intervalId !== undefined) {
        clearInterval(intervalId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDate]);

  const [errorState, setErrorState] = useState(
    Array(dummyOrder.length).fill({}),
  );

  const hasAnyError = errorState.some(
    (error) =>
      error.pendingBag === true ||
      error.taxAmount === true ||
      error.rate === true,
  );

  //To check button should be enabled or not
  const isMoveDisabled =
    isEmptyField(billNumber) ||
    selectedFirm === "" ||
    isEmptyField(userName) ||
    hasAnyError ||
    userNameError !== "";

  const handleFieldChange = (field, value) => {
    switch (field) {
      case billNumberText: {
        setBillUpdateError("");
        setUpdateClick();
        setBillNumber(value);
        break;
      }

      case firmNameText:
        setSelectedFirm(value);
        break;

      case userNameText: {
        if (isValidAlphabeticalInput(value)) {
          setUserName(value);
          setUserNameError("");
        } else {
          setUserName(value);
          setUserNameError(onlyAlphabetsAreAllowedText);
        }
        break;
      }

      case yourCommentsText:
        setUserComments(value);
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div className="bill-modal-scrollable-content">
        {partyGroups.status === 200 && partyGroups.data.length !== 0 && (
          <ModifyParty
            modifiedParty={modifiedParty}
            setModifiedParty={setModifiedParty}
            partyGroups={partyGroups}
            setPartyGroups={setPartyGroups}
          />
        )}

        <OrderSelectedDetailsTable
          dummyOrder={dummyOrder}
          setDummyOrder={setDummyOrder}
          filteredOrders={filteredOrders}
          errorState={errorState}
          setErrorState={setErrorState}
        />

        <BillInputFields
          billNumber={billNumber}
          selectedFirm={selectedFirm}
          userName={userName}
          userComments={userComments}
          selectedDate={selectedDate}
          setUpdateDate={setUpdateDate}
          setSelectedDate={setSelectedDate}
          handleFieldChange={handleFieldChange}
          userNameError={userNameError}
        />
      </div>

      <Grid container className="bill-right-icon-style">
        <BillArrowIcon
          ArrowCircleIcon={ArrowCircleRightIcon}
          isIconDisabled={isMoveDisabled}
          isArrowIcon={isArrowIcon}
          setIsArrowIcon={setIsArrowIcon}
        />
      </Grid>

      {billUpdateError && (
        <Typography className="modal-error-text">{billUpdateError}</Typography>
      )}
    </>
  );
};
