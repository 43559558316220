import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { uiTexts } from "../Utils/uiTexts";
import { useSelector } from "react-redux";
import useRetrieveFunctions from "../CustomHooks/useRetrieveFunctions";
import { NavigateBackIcon } from "../CommonComp/NavigateBackIcon";
import { SearchField } from "../CommonComp/SearchField";
import { EditBillLhsContent } from "./EditBillsLhsContent/EditBillsLhsContent";
import { EditBillsRhsContent } from "./EditBillsRhsContent/EditBillsRhsContent";
import "../CustomersTab/CustomerPage.css";
import "../EditBills/editbills.css";
import { Loader } from "../Loader/Loader";

// ============================================|| EDIT BILLS ||============================================ //

const EditBills = () => {
  const {
    placeHolderTexts: { searchCustomerText },
  } = uiTexts;

  const {
    retrieveSalesParametersData,
    retrieveAllBillsData,
    retrieveAllPendingBillsData,
  } = useRetrieveFunctions();

  useEffect(() => {
    retrieveSalesParametersData();
    retrieveAllBillsData();
    retrieveAllPendingBillsData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { pendingBillsData, billsData, salesParametersData } = useSelector(
    (state) => state.data_menu,
  );

  const [searchList, setSearchList] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState();

  const filteredCustomersData = pendingBillsData?.filter((party) =>
    party.partyName.toLowerCase().includes(searchList.toLowerCase()),
  );

  const isLoader =
    pendingBillsData?.length === 0 &&
    billsData?.length === 0 &&
    salesParametersData?.length === 0;

  return (
    <>
      <Header />

      {isLoader ? (
        <Loader />
      ) : (
        <>
          <div className="edit-bill-back-icon customer-page-style">
            <NavigateBackIcon />
          </div>

          <div className="customer-page-style customer-search-container">
            <SearchField
              searchList={searchList}
              setSearchList={setSearchList}
              searchLabel={searchCustomerText}
              setSelectedData={setSelectedCustomer}
            />
          </div>

          <div className="customer-page-style customer-search-container">
            <Grid container className="order-list">
              <EditBillLhsContent
                searchList={searchList}
                setSelectedCustomer={setSelectedCustomer}
                selectedCustomer={selectedCustomer}
                filteredCustomersData={filteredCustomersData}
              />

              {selectedCustomer && (
                <EditBillsRhsContent
                  selectedCustomer={selectedCustomer}
                  setSelectedCustomer={setSelectedCustomer}
                />
              )}
            </Grid>
          </div>
        </>
      )}

      <Footer />
    </>
  );
};

export default EditBills;
