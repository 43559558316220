import React from "react";
import { Table, TableBody, Stack, Typography } from "@mui/material";
import { uiTexts } from "../../Utils/uiTexts";
import { capitalizeFirstLowercaseRest } from "../../Utils/common";

//-------------------------------------------||  ACCORDION LIST HEADER || ---------------------------------------------

export const AccordionListHeader = ({ customerDataByIndex, customer }) => {
  const {
    labels: {
      areaText,
      addresstext,
      contactNumberText,
      gstNumberText,
      panNumberText,
    },
    alertLabels: { isMissingText },
    separators: { colonText },
  } = uiTexts;

  const InfoRow = ({ label, value }) => (
    <Stack direction="row" className="payment-header-text">
      <Typography className="payment-header-text-label">{label}</Typography>

      <Typography className="colon-style">{colonText}</Typography>

      <Typography
        className={`pendingAmount-text ${
          !value ? "customer-accordion-warnings" : ""
        }`}
      >
        {value ? value : `${label} ${isMissingText}`}
      </Typography>
    </Stack>
  );

  return (
    <Table>
      <TableBody>
        <InfoRow
          label={areaText}
          value={capitalizeFirstLowercaseRest(customer.areaName)}
        />

        <InfoRow label={addresstext} value={customerDataByIndex.address} />

        <InfoRow
          label={contactNumberText}
          value={customerDataByIndex.contactNo}
        />

        <InfoRow label={gstNumberText} value={customerDataByIndex.gstNo} />

        <InfoRow label={panNumberText} value={customerDataByIndex.panNo} />
      </TableBody>
    </Table>
  );
};
