import React from "react";
import { RupeeIconWithLabel } from "../CommonComp/RupeeIconWithLabel";
import { uiTexts } from "../Utils/uiTexts";

// ===================================================|| HEADER DETAILS ||=================================================== //

export const HeaderDetails = ({
  isTransportDetails,
  customerResponsibility,
}) => {
  const {
    labels: {
      sNoText,
      dateText,
      itemNameText,
      quantityText,
      rateText,
      amountText,
      taxText,
      netText,
      bagText,
      weightText,
      brandNameText,
      fareText,
      netFareText,
      deliveryRateText,
    },
    separators: { leftParenthesisText, rightParenthesisText },
    buttonText: { noText },
  } = uiTexts;

  const commonHeaders = [
    { label: sNoText },
    { label: dateText },
    { label: itemNameText },
    {
      label: `${quantityText} ${leftParenthesisText}${bagText}${rightParenthesisText}`,
    },
    { label: weightText },
  ];

  const billHeaders = [
    {
      label: (
        <RupeeIconWithLabel
          text={rateText}
          labelClassName="bill-modal-font bold-text"
        />
      ),
    },
    {
      label: (
        <RupeeIconWithLabel
          text={deliveryRateText}
          labelClassName="bill-modal-font bold-text"
        />
      ),
    },
    {
      label: (
        <RupeeIconWithLabel
          text={`${taxText} ${amountText}`}
          labelClassName="bill-modal-font bold-text"
        />
      ),
    },
    {
      label: (
        <RupeeIconWithLabel
          text={`${netText} ${amountText}`}
          labelClassName="bill-modal-font bold-text"
        />
      ),
    },
  ];

  const transportHeaders = [
    { label: brandNameText },
    ...(customerResponsibility === noText
      ? [
          {
            label: (
              <RupeeIconWithLabel
                text={fareText}
                labelClassName="bill-modal-font bold-text"
              />
            ),
          },
          {
            label: (
              <RupeeIconWithLabel
                text={netFareText}
                labelClassName="bill-modal-font bold-text"
              />
            ),
          },
        ]
      : []),
  ];

  return isTransportDetails
    ? [...commonHeaders, ...billHeaders]
    : [...commonHeaders, ...transportHeaders];
};
