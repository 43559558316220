import React from "react";
import { TableCell, TableRow, Collapse, Grid } from "@mui/material";
import { uiTexts } from "../../../../Utils/uiTexts";
import { OrderFields } from "../../Bill Details/OrderFields";
import { GatePassIconButtons } from "./GatePassIconButtons";

// =======================================|| Gate Pass For Ordered Item ||========================================== //

export const GatePassForOrderedItem = ({
  isExpanded,
  quantity,
  vehicleNumber,
  quantityError,
  handleFieldChange,
  detailIndex,
  orderIndex,
  removeGatePassDetail,
  addGatePassDetail,
  showAddIcon,
  showRemoveIcon,
}) => {
  const {
    labels: {
      quantityText,
      vehicleNumberText,
      quantityLowerCaseText,
      vehicleNumberLowerCaseText,
    },
    placeHolderTexts: { enterQuantityText, enterVehicleNumberText },
  } = uiTexts;

  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <Grid container className="bill-modal-expand-area">
            <OrderFields
              label={quantityText}
              placeHolder={enterQuantityText}
              value={quantity}
              xs1={1.5}
              xs2={3}
              onChange={(e) =>
                handleFieldChange(
                  orderIndex,
                  detailIndex,
                  quantityLowerCaseText,
                  e.target.value,
                )
              }
              errorText={quantityError}
            />

            <OrderFields
              label={vehicleNumberText}
              placeHolder={enterVehicleNumberText}
              value={vehicleNumber}
              xs1={1.5}
              xs2={3}
              onChange={(e) =>
                handleFieldChange(
                  orderIndex,
                  detailIndex,
                  vehicleNumberLowerCaseText,
                  e.target.value,
                )
              }
            />

            <GatePassIconButtons
              showRemoveIcon={showRemoveIcon}
              removeGatePassDetail={removeGatePassDetail}
              orderIndex={orderIndex}
              detailIndex={detailIndex}
              showAddIcon={showAddIcon}
              addGatePassDetail={addGatePassDetail}
            />
          </Grid>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};
