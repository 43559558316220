import React from "react";
import { Button, Grid, ListItem, Typography } from "@mui/material";
import storeIcon from "../../Image/storeIcon.png";
import "../OrderPage.css";

// ===================================================|| ORDER LIST DETAILS ||=================================================== //

export const OrderListDetails = ({
  selectedCustomer,
  handleCustomerSelect,
  filteredCustomers,
}) => {
  return (
    <>
      {filteredCustomers?.map(
        (orders, index) =>
          orders.totalPendingOrders !== 0 && (
            <Button
              className={
                selectedCustomer && orders.partyId === selectedCustomer.partyId
                  ? "orders-payment-content--button orders-payment-content--button--selected"
                  : "orders-payment-content--button"
              }
              key={index + 1}
              onClick={() => {
                handleCustomerSelect(orders);
              }}
            >
              <ListItem>
                <Grid container>
                  <Grid item xs={9}>
                    <Typography className="customer-details-list-text">
                      <img
                        src={storeIcon}
                        className="storeIcon-style"
                        alt="logo"
                      />
                      {orders.partyName}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography className="customer-details-list-text">
                      {orders.totalPendingOrders}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            </Button>
          ),
      )}
    </>
  );
};
