import React from "react";
import { Typography } from "@mui/material";

// ============================================|| EMPTY REPORT DATA ||============================================ //

export const EmptyReportData = ({ warningLabel }) => {
  return (
    <div className="page-not-found">
      <Typography className="page-not-found-text">{warningLabel}</Typography>
    </div>
  );
};
