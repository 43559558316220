import React from "react";
import { Grid, Typography, TextField, MenuItem } from "@mui/material";
import { uiTexts } from "../../../Utils/uiTexts";

// ===================================================|| TEXT FIELD ||=================================================== //

export const OrderFields = ({
  label,
  value,
  onChange,
  select,
  options,
  multiline,
  placeHolder,
  rows,
  xs1,
  xs2,
  errorText,
  isEditBillModal,
}) => {
  const {
    labels: { offText },
  } = uiTexts;

  return (
    <>
      <Grid item xs={xs1}>
        <Typography className="bill-modal-text">{label}</Typography>
      </Grid>

      <Grid item xs={xs2}>
        <TextField
          label={placeHolder}
          value={value}
          onChange={onChange}
          select={select}
          multiline={multiline}
          autoComplete={offText}
          rows={rows}
          style={{ width: "260px" }}
          helperText={errorText}
          error={errorText}
          FormHelperTextProps={{
            style: { color: "var(--common-error-color)" },
          }}
          disabled={isEditBillModal}
        >
          {select &&
            options?.map((option, index) => (
              <MenuItem key={index} value={option.value || option}>
                {option.label || option}
              </MenuItem>
            ))}
        </TextField>
      </Grid>
    </>
  );
};
