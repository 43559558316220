import React from "react";
import { Alert, Typography } from "@mui/material";
import { uiTexts } from "../Utils/uiTexts";

// ===================================================|| WARNINGS ||=================================================== //

export const CustomerWarnings = ({ countMissingData }) => {
  const {
    alertLabels: { infoText, customersHaveMissingInformationText },
    separators: { colonText },
  } = uiTexts;

  return (
    <Alert severity="warning" className="customer-warning-alert">
      <Typography className="customer-warning-text">
        {infoText} {colonText} {countMissingData}{" "}
        {customersHaveMissingInformationText}
      </Typography>
    </Alert>
  );
};
