import React from "react";
import { Grid, Button } from "@mui/material";
import { uiTexts } from "../../../Utils/uiTexts";

// ===================================================|| ACTION BUTTONS ||=================================================== //

export const PaymentActionButtons = ({
  isUpdateDisabled,
  handleUpdate,
  handleCancel,
  updateClick,
  setUpdateClick,
}) => {
  const {
    buttonText: { updateText, cancelText },
  } = uiTexts;

  const ActionButton = ({ onClick, disabled, text, buttonStyle }) => (
    <Button className={buttonStyle} onClick={onClick} disabled={disabled}>
      {text}
    </Button>
  );

  const handleUpdateClick = () => {
    setUpdateClick(true);
    handleUpdate();
  };

  return (
    <Grid
      item
      xs={12}
      container
      justifyContent="right"
      spacing={3}
      className="payment-modal-action-button"
    >
      <Grid item>
        <ActionButton
          onClick={handleCancel}
          text={cancelText}
          disabled={updateClick === true}
          buttonStyle={
            updateClick === true
              ? "bill-modal-button bill-modal-cancel-button-disabled"
              : "bill-modal-button bill-modal-cancel-button"
          }
        />
      </Grid>

      <Grid item>
        <ActionButton
          onClick={handleUpdateClick}
          text={updateText}
          buttonStyle={
            isUpdateDisabled
              ? "bill-modal-button bill-modal-update-button-disabled-bg-color"
              : "bill-modal-button bill-modal-update-button-bg-color"
          }
          disabled={isUpdateDisabled}
        />
      </Grid>
    </Grid>
  );
};
