import React from "react";
import {
  calculateTotalQuantity,
  getPendingBagOrQuantity,
} from "../../../../Utils/common";
import { uiTexts } from "../../../../Utils/uiTexts";
import { GatePassForOrderedItem } from "../GatePass/GatePassForOrderedItem";

// =======================================|| GATEPASS DETAILS ||========================================== //

export const GatePassDetails = ({ gatePassData, setGatePassData, index }) => {
  const {
    labels: { quantityLowerCaseText },
    warnings: {
      onlyNumbersAllowedText,
      verifyTheQuantityText,
      inValidValueText,
    },
  } = uiTexts;

  const calculateQuantity = (details, excludeIndex) => {
    return details
      .filter((_, index) => index !== excludeIndex)
      .reduce((total, detail) => total + (parseInt(detail.quantity) || 0), 0);
  };

  const getQuantityError = (isNumbersPresent, isValidQuantity, isZero) => {
    if (!isNumbersPresent) return onlyNumbersAllowedText;
    if (!isValidQuantity) return verifyTheQuantityText;
    if (isZero) return inValidValueText;
    return "";
  };

  const getQuantityValue = (isNumeric, value) => {
    if (isNumeric(value)) {
      if (value !== "") return parseInt(value);
      else return "";
    } else return value;
  };

  const gatePassDetailChange = (orderIndex, detailIndex, field, value) => {
    const isNumeric = (value) => /^\d*$/.test(value);
    const isZero = parseInt(value) === 0;
    const updatedOrderData = [...gatePassData];

    const totalQuantity = calculateQuantity(
      updatedOrderData[orderIndex].gatePassDetails,
      detailIndex,
    );

    const quantityValue = getQuantityValue(isNumeric, value);
    const pendingBag = getPendingBagOrQuantity(updatedOrderData[orderIndex]);

    const isValidQuantity =
      isNumeric(value) && totalQuantity + quantityValue <= pendingBag;

    if (field === quantityLowerCaseText) {
      updatedOrderData[orderIndex].gatePassDetails[detailIndex].quantityError =
        getQuantityError(isNumeric(value), isValidQuantity, isZero);
    }

    updatedOrderData[orderIndex].gatePassDetails[detailIndex][field] =
      quantityValue;

    const newTotalQuantity = calculateQuantity(
      updatedOrderData[orderIndex].gatePassDetails,
    );

    if (newTotalQuantity <= pendingBag) {
      updatedOrderData[orderIndex].gatePassDetails.forEach((detail) => {
        if (detail[field] !== "" && detail[field] !== 0) {
          const hasNonNumericCharacters = /[^0-9]/.test(detail[field]);
          if (!hasNonNumericCharacters) {
            detail.quantityError = "";
          }
        }
      });
    }

    setGatePassData(updatedOrderData);
  };

  // Function to remove gate pass detail
  const removeGatePassDetail = (orderIndex, detailIndex) => {
    const updatedOrderData = [...gatePassData];
    updatedOrderData[orderIndex].gatePassDetails.splice(detailIndex, 1);

    const totalQuantity = updatedOrderData[orderIndex].gatePassDetails.reduce(
      (total, detail) => total + (parseInt(detail.quantity) || 0),
      0,
    );

    const pendingBag = getPendingBagOrQuantity(updatedOrderData[orderIndex]);

    const quantityError =
      totalQuantity === 0
        ? inValidValueText
        : totalQuantity > pendingBag
        ? verifyTheQuantityText
        : "";

    updatedOrderData[orderIndex].gatePassDetails.forEach((detail) => {
      detail.quantityError = quantityError;
    });

    setGatePassData(updatedOrderData);
  };

  // Function to add a new gate pass detail
  const addGatePassDetail = (orderIndex) => {
    const updatedOrderData = [...gatePassData];
    updatedOrderData[orderIndex].gatePassDetails.push({
      quantity: "",
      vehicleNumber: "",
      quantityError: "",
    });

    setGatePassData(updatedOrderData);
  };

  const isShowAddIcon = (detailIndex, orderIndex) => {
    const totalQuantity = calculateTotalQuantity(gatePassData, orderIndex);
    const gatePassDetails = gatePassData[orderIndex]?.gatePassDetails || [];
    const pendingBag = getPendingBagOrQuantity(gatePassData[orderIndex]);
    const isQuantityValid = gatePassDetails.every(
      (detail) => detail.quantity !== "" && detail.quantityError === "",
    );

    return (
      totalQuantity < pendingBag &&
      totalQuantity !== 0 &&
      detailIndex === gatePassData[orderIndex].gatePassDetails.length - 1 &&
      isQuantityValid
    );
  };

  return (
    <>
      {gatePassData[index]?.gatePassDetails.map((detail, detailIndex) => {
        const showAddIcon = isShowAddIcon(detailIndex, index);
        const showRemoveIcon = gatePassData[index].gatePassDetails.length !== 1;

        return (
          <GatePassForOrderedItem
            key={detailIndex}
            isExpanded={true}
            quantity={detail.quantity}
            vehicleNumber={detail.vehicleNumber}
            quantityError={detail.quantityError}
            handleFieldChange={gatePassDetailChange}
            detailIndex={detailIndex}
            orderIndex={index}
            removeGatePassDetail={removeGatePassDetail}
            addGatePassDetail={addGatePassDetail}
            showAddIcon={showAddIcon}
            showRemoveIcon={showRemoveIcon}
          />
        );
      })}
    </>
  );
};
