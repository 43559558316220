import { configureStore } from "@reduxjs/toolkit";

import reducers from "./Reducers";

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const store = configureStore({
  reducer: reducers,
});

const { dispatch } = store;

export { store, dispatch };
