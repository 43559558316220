import React from "react";
import { TableBody, TableRow, TableCell } from "@mui/material";
import {
  formatDate,
  handleUnknown,
  capitalizeFirstLowercaseRest,
} from "../../../Utils/common";
import { uiTexts } from "../../../Utils/uiTexts";

// ============================================|| ACCORDION DETAILS SUMMARY TABLE ITEM ||============================================ //

export const ItemListAccordionTableItem = ({
  itemDetailsByIndex,
  itemOrderDetailsByIndex,
}) => {
  const {
    labels: { unKnownText },
    separators: { hyphenText },
  } = uiTexts;

  const InfoRow = ({ value, className }) => (
    <TableCell className={className}>{value}</TableCell>
  );

  const getOrderUnitDetails = (item) => {
    return itemOrderDetailsByIndex.find(
      (order) => order.id === item.orderItemTransactionId,
    );
  };

  return (
    <TableBody>
      {itemDetailsByIndex &&
        itemDetailsByIndex?.map((item, i) => {
          const orderUnitDetails = getOrderUnitDetails(item);
          return (
            <TableRow key={i}>
              <InfoRow
                value={formatDate(item.date)}
                className="pendingOrder-text"
              />

              <InfoRow value={item.party} className="pendingOrder-text" />

              <InfoRow
                value={handleUnknown(
                  unKnownText,
                  orderUnitDetails.unitListName,
                  hyphenText,
                )}
                className="pendingOrder-text"
              />

              <InfoRow
                value={handleUnknown(
                  unKnownText,
                  capitalizeFirstLowercaseRest(
                    orderUnitDetails.cleanCategoryName,
                  ),
                  hyphenText,
                )}
                className="pendingOrder-text"
              />

              <InfoRow
                value={handleUnknown(
                  unKnownText,
                  item.quantityInKg,
                  item.originalBag,
                )}
                className="pendingOrder-text"
              />

              <InfoRow
                value={item.dispatchedBag}
                className="pendingOrder-text"
              />

              <InfoRow value={item.pendingBag} className="pendingOrder-text" />
            </TableRow>
          );
        })}
    </TableBody>
  );
};
