import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Button, ListItem, Typography, Stack } from "@mui/material";
import { EditBillModal } from "../EditBillsModal/EditBillModal";
import BillIcon from "../../Image/billIcon.png";
import { getOrderItemTransactionById } from "../../Services/OrderService";
import "../../CustomersTab/CustomerPage.css";
import "../../EditBills/editbills.css";

// ============================================|| EDIT BILLS RHS CONTENT ||============================================ //

export const EditBillsRhsContent = ({
  selectedCustomer,
  setSelectedCustomer,
}) => {
  const { billsData } = useSelector((state) => state.data_menu);

  const [selectedBill, setSelectedBill] = useState("");
  const [orderItems, setOrderItems] = useState([]);
  const [billClick, setBillClick] = useState(false);
  const [customerDetailsSelect, setCustomerDetailsSelect] = useState([]);
  const [partyGroups, setPartyGroups] = useState({ data: [], status: null });
  const [selectedBillData, setSelectedBillData] = useState();
  const [selectedBillBag, setSelectedBillBag] = useState();
  const [selectedBillId, setSelectedBillId] = useState();

  const handleBillSelection = async (billNo, billId) => {
    const bill = billsData?.find(
      (bill) => bill.billNo === billNo && bill.id === billId,
    );

    if (bill) {
      setSelectedBill(billNo);
      setSelectedBillId(billId);
      setSelectedBillData(bill);

      const orderItems = [];
      for (const id of bill?.orderItemTransactionIds) {
        const orderItemData = await getOrderItemTransactionById(id);
        orderItems.push(orderItemData?.data);
      }

      const newDetails = orderItems?.map((orderItem) => {
        const detailFromBill = bill?.details.find(
          (detail) => detail.orderItemTransactionId === orderItem.id,
        );

        setSelectedBillBag(detailFromBill ? detailFromBill.bag : 0);

        return {
          ...orderItem,
          rate: detailFromBill ? detailFromBill.rate : 0,
          bag: detailFromBill ? detailFromBill.bag : 0,
          orderDate: detailFromBill ? orderItem.orderDate : null,
          taxAmount: detailFromBill ? detailFromBill.taxAmount : 0,
          netAmount: detailFromBill ? detailFromBill.netAmount : 0,
        };
      });

      setSelectedBillData((prevData) => ({
        ...prevData,
        details: newDetails,
      }));

      setBillClick(true);
    }
  };

  const IconText = ({ iconSrc, text }) => (
    <Stack direction="row">
      <img src={iconSrc} className="payment-icon-style" alt="Icon" />
      <Typography className="bill-details-list-style">{text}</Typography>
    </Stack>
  );

  return (
    <>
      <Grid item xs={6}>
        {selectedCustomer?.billNumbers?.map((bill, billIndex) => (
          <React.Fragment key={billIndex}>
            <Button
              fullWidth
              className={`orders-payment-content--button ${
                selectedBill === bill.billNo &&
                "orders-payment-content--button--selected"
              }`}
              onClick={() => handleBillSelection(bill.billNo, bill.billId)}
            >
              <ListItem>
                <Grid container>
                  <Grid item xs={12}>
                    <IconText iconSrc={BillIcon} text={bill.billNo} />
                  </Grid>
                </Grid>
              </ListItem>
            </Button>
          </React.Fragment>
        ))}
      </Grid>

      {billClick && (
        <EditBillModal
          customerDetailsSelect={customerDetailsSelect}
          setCustomerDetailsSelect={setCustomerDetailsSelect}
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
          billClick={billClick}
          setBillClick={setBillClick}
          orderItems={orderItems}
          setOrderItems={setOrderItems}
          partyGroups={partyGroups}
          setPartyGroups={setPartyGroups}
          selectedBill={selectedBill}
          setSelectedBillData={setSelectedBillData}
          selectedBillData={selectedBillData}
          selectedBillBag={selectedBillBag}
          setSelectedBillBag={setSelectedBillBag}
          setSelectedBillId={setSelectedBillId}
          selectedBillId={selectedBillId}
        />
      )}
    </>
  );
};
