import React, { useState, useRef } from "react";
import { Grid, Box } from "@mui/material";
import { HeaderInfoList } from "./HeaderInfoList";
import { useNavigate } from "react-router-dom";
import { uiTexts } from "../../Utils/uiTexts";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { HeaderProfileList } from "../HeaderRHS/HeaderProfileList";
import "../Header.css";

// ===================================================|| HEADER RHS ||=================================================== //

export const HeaderRhs = () => {
  const {
    buttonText: { reportText },
    toolTipText: { moreInfoText, profileText },
  } = uiTexts;

  const navigate = useNavigate();
  const anchorRefInfo = useRef(null);
  const anchorRefLogout = useRef(null);

  const [isOpenInfoMenu, setIsOpenInfoMenu] = useState(false);
  const [isOpenLogoutMenu, setIsOpenLogoutMenu] = useState(false);

  const handleToggle = () => {
    setIsOpenInfoMenu((prevOpen) => !prevOpen);
  };

  const handleToggleLogoutMenu = () => {
    setIsOpenLogoutMenu((prevOpen) => !prevOpen);
  };

  const HeaderIcon = ({ ref, IconComponent, tooltipTitle, onClick }) => (
    <Box
      data-mdb-toggle="tooltip"
      title={tooltipTitle}
      ref={ref}
      className="header-icon-button header-info-logout-icon"
      onClick={onClick}
    >
      <IconComponent className="header-icon" />
    </Box>
  );

  return (
    <Grid item>
      <Grid
        container
        alignItems="center"
        spacing={1}
        className="header-icons-container"
      >
        <HeaderIcon
          IconComponent={InsertDriveFileIcon}
          tooltipTitle={reportText}
          onClick={() => navigate("/report")}
        />

        <HeaderIcon
          ref={anchorRefInfo}
          IconComponent={InfoOutlinedIcon}
          tooltipTitle={moreInfoText}
          onClick={handleToggle}
        />

        <HeaderIcon
          ref={anchorRefLogout}
          IconComponent={AccountCircleIcon}
          tooltipTitle={profileText}
          onClick={handleToggleLogoutMenu}
        />

        <HeaderInfoList
          isOpenInfoMenu={isOpenInfoMenu}
          setIsOpenInfoMenu={setIsOpenInfoMenu}
          anchorRef={anchorRefInfo}
        />

        <HeaderProfileList
          isOpenLogoutMenu={isOpenLogoutMenu}
          setIsOpenLogoutMenu={setIsOpenLogoutMenu}
          anchorRef={anchorRefLogout}
        />
      </Grid>
    </Grid>
  );
};
