import { combineReducers } from "redux";

import data_menu from "./data_menu";
import status_menu from "./status_menu";

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  data_menu,
  status_menu,
});

export default reducers;
