import React from "react";
import { Stack, Typography, Box } from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { uiTexts } from "../Utils/uiTexts";

// ==========================================|| RUPEE ICON WITH LABEL ||========================================== //

export const RupeeIconWithLabel = ({ text, labelClassName }) => {
  const {
    separators: { leftParenthesisText, rightParenthesisText },
  } = uiTexts;

  return (
    <Stack direction="column" alignItems="flex-start">
      <Typography className={labelClassName}>{text}</Typography>
      <Box display="flex" alignItems="center">
        <Typography>{leftParenthesisText}</Typography>
        <CurrencyRupeeIcon fontSize="10px" />
        <Typography>{rightParenthesisText}</Typography>
      </Box>
    </Stack>
  );
};
