import React from "react";
import { TableCell, TableHead, TableRow, Typography } from "@mui/material";

// ============================================|| REPORT TABLE HEADER ||============================================ //

export const ReportTableHeader = ({ reportHeader }) => {
  return (
    <TableHead
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 2,
        backgroundColor: "#d5dbe4",
      }}
    >
      <TableRow>
        {reportHeader?.map((header, index) => (
          <TableCell key={index}>
            <Typography className="report-table-header-row">
              {header}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
