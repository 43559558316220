import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  TableContainer,
  Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  getCustomerOrderHistoryById,
  getCustomerDetailsById,
} from "../../Services/DataService";
import { uiTexts } from "../../Utils/uiTexts";
import { useSelector } from "react-redux";
import { Loader } from "../../Loader/Loader";
import { AccordionListHeader } from "./AccordionListHeader";
import { AccordionDetailsSummary } from "./AccordionDetailsSummary/AccordionDetailsSummary";
import { AccordionHeader } from "./AccordionHeader";
import { SearchField } from "../../CommonComp/SearchField";
import { NavigateBackIcon } from "../../CommonComp/NavigateBackIcon";
import "../CustomerPage.css";

//-------------------------------------------|| CUSTOMER ACCORDION DISPLAY || ---------------------------------------------

export const AccordionDisplay = () => {
  const {
    warnings: { noDetailsAvailableForThisCustomerText, customerNotFoundText },
    placeHolderTexts: { searchCustomerText },
    labels: { customersText },
  } = uiTexts;

  const { customerList } = useSelector((state) => state.data_menu);

  const [searchList, setSearchList] = useState("");
  const [customerDetails, setCustomerDetails] = useState([]);
  const [openAccordions, setOpenAccordions] = useState([]);
  const [customerLoader, setCustomerLoader] = useState(false);
  const [customerData, setCustomerData] = useState({});

  // Search by Customers and area
  const filteredCustomers = customerList?.filter(
    (customer) =>
      customer.partyName.toLowerCase().includes(searchList.toLowerCase()) ||
      customer.areaName.toLowerCase().includes(searchList.toLowerCase()),
  );

  const isEmptyData =
    customerList?.length !== 0 &&
    (filteredCustomers?.length === 0 ||
      filteredCustomers?.length === undefined);

  useEffect(() => {
    //Close all accordion if seachlist is changed
    setOpenAccordions([]);
  }, [searchList]);

  // Function to handle Accordion expands
  const handleAccordionExpand = async (partyId, index, isExpanded) => {
    if (isExpanded) {
      try {
        setCustomerLoader(true);
        const details = await getCustomerOrderHistoryById(partyId);
        const result = await getCustomerDetailsById(partyId);

        if (details.status === 200) {
          setCustomerDetails((prevDetails) => ({
            ...prevDetails,
            [index]: details.data,
          }));
        }

        if (result.status === 200) {
          setCustomerData((prevData) => ({
            ...prevData,
            [index]: result.data,
          }));
        }
      } catch (error) {
        console.error("Error fetching customer details:", error);
      } finally {
        setCustomerLoader(false);
      }
    }

    setOpenAccordions((prevOpenAccordions) =>
      isExpanded
        ? [...prevOpenAccordions, index]
        : prevOpenAccordions.filter(
            (accordionIndex) => accordionIndex !== index,
          ),
    );
  };

  return (
    <>
      {customerLoader && <Loader />}

      <div className="customer-page-style backIcon-container">
        <NavigateBackIcon iconLabel={customersText} />
      </div>

      <div className="customer-page-style customer-search-container">
        <SearchField
          searchList={searchList}
          setSearchList={setSearchList}
          searchLabel={searchCustomerText}
        />
      </div>

      <div className="customer-list-container">
        {filteredCustomers?.map((customer, index) => (
          <Accordion
            key={index}
            className="customer-accordion"
            expanded={openAccordions.includes(index)}
            onChange={(event, isExpanded) =>
              handleAccordionExpand(customer.partyId, index, isExpanded)
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index + 1}-content`}
              id={`panel${index + 1}-header`}
            >
              <AccordionHeader customer={customer} />
            </AccordionSummary>

            <AccordionDetails className="customer-accordion-container">
              {customerData[index] && customerData[index].length !== 0 && (
                <AccordionListHeader
                  customerDataByIndex={customerData[index]}
                  customer={customer}
                />
              )}
              <TableContainer component={Paper} sx={{ mt: 2 }}>
                {!customerDetails[index] ||
                !Array.isArray(customerDetails[index]) ||
                customerDetails[index].length === 0 ? (
                  <Typography className="no-customer-details-warning">
                    {noDetailsAvailableForThisCustomerText}
                  </Typography>
                ) : (
                  <AccordionDetailsSummary
                    customerDetailsByIndex={customerDetails[index]}
                  />
                )}
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        ))}
        {isEmptyData && (
          <Typography className="error-record-not-found">
            {customerNotFoundText}
          </Typography>
        )}
      </div>
    </>
  );
};
