import React from "react";
import { Grid, IconButton } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

// =======================================|| Gate Pass Icon Buttons ||========================================== //

export const GatePassIconButtons = ({
  showRemoveIcon,
  removeGatePassDetail,
  orderIndex,
  detailIndex,
  showAddIcon,
  addGatePassDetail,
}) => {
  return (
    <>
      {showRemoveIcon && (
        <Grid item xs={0.5}>
          <IconButton
            onClick={() => removeGatePassDetail(orderIndex, detailIndex)}
          >
            <RemoveCircleOutlineIcon className="bill-modal-transport-remove-icon" />
          </IconButton>
        </Grid>
      )}

      {showAddIcon && (
        <Grid item xs={0.5}>
          <IconButton onClick={() => addGatePassDetail(orderIndex)}>
            <AddCircleOutlineIcon className="bill-modal-transport-add-icon" />
          </IconButton>
        </Grid>
      )}
    </>
  );
};
