import React from "react";
import { Table, TableContainer } from "@mui/material";
import { BillModalPaymentDetailsHeader } from "./BillModalPaymentDetailsHeader";
import { BillModalPaymentDetailsItem } from "./BillModalPaymentDetailsItem";

// ===================================================|| MODAL PAYMENT DETAILS ||=================================================== //

export const BillModalPaymentDetails = ({ filteredBillDetails }) => {
  return (
    <>
      {filteredBillDetails?.map(
        (bill, index) =>
          bill.paymentDetails?.length > 0 && (
            <TableContainer
              key={bill.billNo}
              className="modal-payment-details-style"
            >
              <Table>
                <BillModalPaymentDetailsHeader />

                <BillModalPaymentDetailsItem
                  filteredBillDetails={filteredBillDetails}
                />
              </Table>
            </TableContainer>
          ),
      )}
    </>
  );
};
