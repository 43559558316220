import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { uiTexts } from "../Utils/uiTexts";
import "../OrdersTab/OrderPage.css";

// ============================================|| SUCCESS ALERT ||============================================ //

export const SuccessAlert = ({ isOpenAlert, setIsOpenAlert, alertText }) => {
  const {
    alertLabels: { clickAwayText },
  } = uiTexts;

  // Creating a custom Alert component with React.forwardRef to pass a ref and spread props to MuiAlert
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  // close alert message
  const handleClose = (event, reason) => {
    if (reason === clickAwayText) {
      return;
    }

    setIsOpenAlert(false);
  };

  return (
    <Snackbar open={isOpenAlert} autoHideDuration={6000} onClose={handleClose}>
      <Alert
        onClose={handleClose}
        severity="success"
        className="orders-page-snackbar"
      >
        {alertText}
      </Alert>
    </Snackbar>
  );
};
