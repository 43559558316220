import React from "react";
import { DialogTitle, IconButton, Typography } from "@mui/material";
import BillIcon from "../Image/billIcon.png";
import CloseIcon from "@mui/icons-material/Close";
import "../PaymentTab/PaymentPage.css";

// ===================================================|| BILL MODAL ||=================================================== //

export const BillDialogTitle = ({ onClose, titleLabel }) => {
  return (
    <DialogTitle>
      <div>
        <img src={BillIcon} className="billIcon-style" alt="logo" />
        <Typography className="bill-modal-header">{titleLabel}</Typography>
      </div>

      <IconButton
        aria-label="close"
        onClick={onClose}
        className="bill-modal-close-iconbutton"
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};
