import React from "react";
import { Grid, Typography } from "@mui/material";
import pcsLogo from "../Image/pcs.jpg";
import { uiTexts } from "../Utils/uiTexts";
import "./Header.css";

// ===================================================|| HEADER LHS ||=================================================== //

export const HeaderLhs = () => {
  const {
    headerLabels: { agriTechUpperCaseText },
  } = uiTexts;

  return (
    <Grid item>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <img src={pcsLogo} className="pcs-logo" alt="logo" />
        </Grid>

        <Grid item>
          <Typography className="agritech-text">
            {agriTechUpperCaseText}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
