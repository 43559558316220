import React from "react";
import { IconButton } from "@mui/material";

// ===================================================|| BILL ARROW ICON ||=================================================== //

export const BillArrowIcon = ({
  ArrowCircleIcon,
  isIconDisabled,
  setIsArrowIcon,
  isArrowIcon,
  iconGridCss,
}) => {
  return (
    <IconButton
      disabled={isIconDisabled}
      className={
        isIconDisabled
          ? "bill-modal-arrow-icon-disabled"
          : "bill-modal-arrow-icon"
      }
      onClick={() => setIsArrowIcon(!isArrowIcon)}
    >
      <ArrowCircleIcon />
    </IconButton>
  );
};
