import React, { useState } from "react";
import { Grid } from "@mui/material";
import { BillSelectedModal } from "../BillDetails Modal/BillSelectedModal";
import { BillList } from "./BillList";
import { getOrderItemTransactionById } from "../../Services/OrderService";
import { useDispatch } from "react-redux";
import { SelectedBillDetails } from "../../Store/Reducers/data_menu";
import { Loader } from "../../Loader/Loader";
import "../PaymentPage.css";

// ===================================================|| PAYMENT DETAILS ||=================================================== //

export const PaymentDetails = ({
  selectedPayment,
  selectedBill,
  setSelectedBill,
  selectedPaymentDetails,
  setSelectedPaymentDetails,
  filteredBillDetails,
  setFilteredBillDetails,
  setSelectedPayment,
  setIsOpenAlert,
}) => {
  const dispatch = useDispatch();

  const [isBillSelected, setIsBillSelected] = useState(false);
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [orderItemTransactionIdData, setOrderItemTransactionIdData] = useState(
    [],
  );

  const handleBillDetails = async (bill) => {
    setPaymentLoader(true);
    dispatch(SelectedBillDetails(bill));

    const { orderItemTransactionIds } = bill;
    //Make api call to get orderItemTransaction details
    try {
      const orderItemDetailsPromises = orderItemTransactionIds?.map((id) =>
        getOrderItemTransactionById(id),
      );
      const results = await Promise.all(orderItemDetailsPromises);

      const orderItemData = results?.map((result) => result.data);
      setOrderItemTransactionIdData(orderItemData);

      if (selectedPayment.paidAmount !== 0) {
        const filteredDetails = selectedPaymentDetails?.filter(
          (res) => res.billNo === bill.billNo,
        );
        setFilteredBillDetails(filteredDetails);
      }

      setPaymentLoader(false);
      setIsBillSelected(true);
    } catch (error) {
      console.error("Error fetching order item details:", error);
    }
  };

  return (
    <>
      {paymentLoader && <Loader />}

      <Grid item xs={6}>
        <div className="list-container payment-details-tab-height">
          <BillList
            selectedPayment={selectedPayment}
            handleBillDetails={handleBillDetails}
          />
        </div>
      </Grid>

      {isBillSelected && (
        <BillSelectedModal
          selectedPayment={selectedPayment}
          isBillSelected={isBillSelected}
          setIsBillSelected={setIsBillSelected}
          orderItemTransactionIdData={orderItemTransactionIdData}
          setOrderItemTransactionIdData={setOrderItemTransactionIdData}
          selectedPaymentDetails={selectedPaymentDetails}
          setSelectedPaymentDetails={setSelectedPaymentDetails}
          filteredBillDetails={filteredBillDetails}
          setFilteredBillDetails={setFilteredBillDetails}
          setSelectedPayment={setSelectedPayment}
          setIsOpenAlert={setIsOpenAlert}
        />
      )}
    </>
  );
};
