import React from "react";
import { TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { uiTexts } from "../../../Utils/uiTexts";

// ============================================|| ACCORDION DETAILS SUMMARY HEADER ||============================================ //

export const ItemListAccordionTableHeader = () => {
  const {
    labels: {
      dateText,
      unitText,
      categoryText,
      orderedBagsText,
      dispatchedBagsText,
      partyText,
    },
    headerLabels: { pendingBagsText },
  } = uiTexts;

  const headers = [
    { label: dateText },
    { label: partyText },
    { label: unitText },
    { label: categoryText },
    { label: orderedBagsText },
    { label: dispatchedBagsText },
    { label: pendingBagsText },
  ];

  return (
    <TableHead>
      <TableRow>
        {headers?.map((header, index) => (
          <TableCell key={index}>
            <Typography className="items-tableCell">{header.label}</Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
