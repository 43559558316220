import React from "react";
import { TableBody, TableCell, TableRow } from "@mui/material";
import {
  formatDate,
  capitalizeFirstLowercaseRest,
} from "../../../Utils/common";

// ============================================|| ACCORDION DETAILS SUMMARY TABLE ITEM ||============================================ //

export const AccordionDetailsSummaryTableItem = ({
  customerDetailsByIndex,
}) => {
  const InfoRow = ({ value }) => (
    <TableCell className="pendingAmount-text">{value}</TableCell>
  );

  return (
    <TableBody>
      {customerDetailsByIndex?.map((transaction, i) => (
        <TableRow key={i}>
          <InfoRow value={formatDate(transaction.orderDate)} />

          <InfoRow
            value={`${transaction.itemName}-${
              transaction.unitListId
            }-${capitalizeFirstLowercaseRest(transaction.cleanCategoryId)}`}
          />

          <InfoRow
            value={capitalizeFirstLowercaseRest(transaction.brokerName)}
          />

          <InfoRow value={transaction.originalBag} />

          <InfoRow value={transaction.dispatchedBag} />

          <InfoRow value={transaction.pendingBag} />
        </TableRow>
      ))}
    </TableBody>
  );
};
