import jsPDF from "jspdf";
import "jspdf-autotable";
import pcs_logo from "../../Image/pcs.jpg";
import { uiTexts } from "../../Utils/uiTexts";

// ===================================================|| DOWNLOAD REPORT ||=================================================== //
const {
  labels: { dateText, firmNameText, categoryText, unKnownText, dotPdfText },
  separators: { colonText, hyphenText, underscoreText },
  placeHolderTexts: { dateInputFormat },
} = uiTexts;

const getColumnStyles = (reportId) => {
  switch (reportId) {
    case 1:
      return {
        0: { cellWidth: 12 },
        1: { cellWidth: 25 },
        2: { cellWidth: 33 },
        3: { cellWidth: 40 },
        4: { cellWidth: 20 },
        5: { cellWidth: 17 },
        6: { cellWidth: 17 },
        7: { cellWidth: 17 },
        8: { cellWidth: 20 },
      };

    case 2:
      return {
        0: { cellWidth: 10 },
        1: { cellWidth: 23 },
        2: { cellWidth: 27 },
        3: { cellWidth: 22 },
        4: { cellWidth: 15 },
        5: { cellWidth: 17 },
        6: { cellWidth: 15 },
        7: { cellWidth: 17 },
        8: { cellWidth: 17 },
        9: { cellWidth: 18 },
        10: { cellWidth: 21 },
      };

    case 3:
      return {
        0: { cellWidth: 8 },
        1: { cellWidth: 24 },
        2: { cellWidth: 24 },
        3: { cellWidth: 23 },
        4: { cellWidth: 12 },
        5: { cellWidth: 15 },
        6: { cellWidth: 22 },
        7: { cellWidth: 17 },
        8: { cellWidth: 18 },
        9: { cellWidth: 20 },
        10: { cellWidth: 20 },
      };

    default:
      return {};
  }
};

export const reportDownload = (
  reportType,
  reportData,
  reportColumns,
  setIsDownloadReport,
  selectedFirmName,
  selectedCategoryName,
  reportDate,
) => {
  const doc = new jsPDF();
  const reportName =
    reportType === 1
      ? "Report 1"
      : reportType === 2
      ? "Report 2"
      : "Dispatch report";

  // Add logo to the top-left corner
  doc.addImage(pcs_logo, "PNG", 10, 10, 15, 15);
  doc.setFontSize(14);
  doc.setFont("Roboto", "bold");
  doc.text(reportName, 100, 20);

  //Replace Unkonwn by hyphenText
  const replaceUnknown = (data) => {
    return Object.values(data).map((entry) => {
      return Object.entries(entry).reduce((acc, [key, value]) => {
        const cleanValue =
          value === unKnownText || value === null || value === ""
            ? hyphenText
            : value;
        // Insert line breaks at commas or hyphens
        acc[key] =
          typeof cleanValue === "string"
            ? cleanValue.replace(/([,-])/g, "$1\n")
            : cleanValue;
        return acc;
      }, {});
    });
  };

  const updatedReportDate = replaceUnknown(reportData);

  // Set up details
  const details = [
    {
      label: `${dateText} ${colonText}`,
      value: reportDate.format(dateInputFormat),
    },
  ];

  if (selectedFirmName) {
    details.push({
      label: `${firmNameText} ${colonText}`,
      value: selectedFirmName,
    });
  }

  if (selectedCategoryName) {
    details.push({
      label: `${categoryText} ${colonText}`,
      value: selectedCategoryName,
    });
  }

  // Set font and color for details
  doc.setFontSize(12);
  doc.setFont("Roboto", "normal");
  doc.setTextColor(0, 0, 0);

  // Align date to the right
  const pageWidth = doc.internal.pageSize.getWidth();
  const marginRight = 10;
  doc.setFont("Roboto", "bold");
  doc.text(
    details[0].label,
    pageWidth - marginRight - doc.getTextWidth(details[0].label) - 20,
    20,
  );
  doc.setFont("Roboto", "normal");
  doc.text(
    details[0].value,
    pageWidth - marginRight - doc.getTextWidth(details[0].value),
    20,
  );

  // Align firm name and item name on the same line if they exist
  let startY = 35;

  details.slice(1).forEach((detail, index) => {
    const xPosition =
      index === 0 ? 10 : pageWidth - 75 - doc.getTextWidth(detail.label);

    doc.setFont("Roboto", "bold");
    const labelWidth = doc.getTextWidth(detail.label);
    doc.text(detail.label, xPosition, startY);

    doc.setFont("Roboto", "normal");
    const valueXPosition = xPosition + labelWidth + 5;
    doc.text(detail.value, valueXPosition, startY);
  });

  const rows = updatedReportDate?.map((data) => Object.values(data));

  const columnStyles = getColumnStyles(reportType);

  // Set the start y position for the table
  const tableStartY = startY + details.length * 2;

  // Add the table
  doc.autoTable({
    startY: tableStartY,
    margin: { left: 5 },
    head: [reportColumns],
    body: rows,
    headStyles: { fillColor: "#d5dbe4", textColor: [0, 0, 0] },
    bodyStyles: { fillColor: "#f2f5f8", textColor: [0, 0, 0] },
    alternateRowStyles: { fillColor: [255, 255, 255] },
    theme: "grid",
    halign: "center",
    valign: "middle",
    styles: {
      fontSize: 9,
      minCellHeight: 9,
    },
    columnStyles: columnStyles,
  });

  // Save the PDF
  let filename =
    reportName + underscoreText + reportDate.format(dateInputFormat);

  if (selectedFirmName) {
    filename += underscoreText + selectedFirmName;
  }

  if (selectedCategoryName) {
    filename += underscoreText + selectedCategoryName;
  }

  filename += dotPdfText;
  doc.save(filename);

  setIsDownloadReport(false);
};
