import React from "react";
import { Typography, Grid, Stack } from "@mui/material";
import grainIcon from "../../Image/grainsIcon.png";
import { uiTexts } from "../../Utils/uiTexts";

// ===================================================|| ACCORDION LIST HEADER ||=================================================== //

export const ItemListAccordionHeader = ({ items }) => {
  const {
    labels: { pendingOrdersText },
    separators: { colonText },
  } = uiTexts;

  const GridItem = ({ text, className, isImage }) => (
    <Stack direction="row" spacing={1}>
      {isImage && (
        <img src={grainIcon} className="payment-icon-style" alt="logo" />
      )}
      <Typography className={className}>{text}</Typography>
    </Stack>
  );

  return (
    <Grid container justifyContent="space-between">
      <GridItem text={items.itemName} className="party-text" isImage={true} />

      <GridItem
        text={`${pendingOrdersText} ${colonText} ${items.orderCount}`}
        className="pendingOrder-text margin-style"
        isImage={false}
      />
    </Grid>
  );
};
