import {
  Box,
  Typography,
  Stack,
  Popper,
  ClickAwayListener,
  Paper,
  MenuItem,
  Grow,
  CardContent,
  Grid,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import bagIcon from "../../Image/Shopping bag.png";
import storeIcon from "../../Image/storeIcon.png";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { uiTexts } from "../../Utils/uiTexts";
import { getUserType } from "../../Utils/common";
import "../Header.css";

// ==============================|| HEADER INFO LIST ||============================== //

export const HeaderInfoList = ({
  isOpenInfoMenu,
  setIsOpenInfoMenu,
  anchorRef,
}) => {
  const {
    labels: { customersText, editBillsText },
    headerLabels: { pendingBagsText },
    userTypesText: { salesTeamText, adminText },
  } = uiTexts;

  const navigate = useNavigate();
  const location = useLocation();

  const handleInfoClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setIsOpenInfoMenu(false);
  };

  const MenuItemWithIcon = ({ icon, text, onClick, iconCss, selectedInfo }) => (
    <MenuItem
      onClick={onClick}
      className={`header-info-list-width ${
        selectedInfo ? "selected-header-info" : "header-info-list-cursor"
      }`}
    >
      <Stack direction="row" spacing={1.2} alignItems="center">
        {/* Check if the icon is a string (image source) or JSX element */}
        {typeof icon === "string" ? (
          <img src={icon} alt={`${text} icon`} className={iconCss} />
        ) : (
          <span className={iconCss}>{icon}</span>
        )}

        <Typography>{text}</Typography>
      </Stack>
    </MenuItem>
  );

  const isDisplayEditButton =
    getUserType() === salesTeamText || getUserType() === adminText;

  return (
    <Box>
      <Popper
        open={isOpenInfoMenu}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        style={{ zIndex: 1002 }}
        className="header-icons-info-menu"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleInfoClose}>
                <CardContent className="info-card">
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    spacing={0.5}
                  >
                    <Grid item>
                      <MenuItemWithIcon
                        icon={bagIcon}
                        text={pendingBagsText}
                        iconCss="header-modal-icons fill-black"
                        onClick={() => navigate("/pending-bags")}
                        selectedInfo={location.pathname === "/pending-bags"}
                      />
                    </Grid>

                    <Grid item>
                      <MenuItemWithIcon
                        icon={storeIcon}
                        text={customersText}
                        iconCss="header-modal-icons fill-black"
                        onClick={() => navigate("/customers")}
                        selectedInfo={location.pathname === "/customers"}
                      />
                    </Grid>

                    {isDisplayEditButton && (
                      <Grid item>
                        <MenuItemWithIcon
                          icon={<EditOutlinedIcon />}
                          text={editBillsText}
                          iconCss="header-modal-icons fill-black"
                          onClick={() => navigate("/editBills")}
                          selectedInfo={location.pathname === "/editBills"}
                        />
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};
