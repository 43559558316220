import React from "react";
import { Grid, Button } from "@mui/material";
import { uiTexts } from "../../../Utils/uiTexts";
import download from "../../../Image/Download.png";

// ===================================================|| REPORT ACTION BUTTONS ||=================================================== //

export const ReportActionButton = ({
  isClearDisabled,
  isDownloadDisabled,
  handleClear,
  handleDownload,
}) => {
  const {
    buttonText: { downloadText, clearText },
  } = uiTexts;

  const ActionButton = ({ onClick, disabled, text, buttonStyle, isIcon }) => (
    <Button className={buttonStyle} onClick={onClick} disabled={disabled}>
      {text}
      {isIcon && (
        <img src={download} className="download-icon-style" alt="logo" />
      )}
    </Button>
  );

  return (
    <Grid item className="report-action-button-container">
      <ActionButton
        onClick={handleClear}
        text={clearText}
        isIcon={false}
        disabled={isClearDisabled === true}
        buttonStyle={
          isClearDisabled === true
            ? "report-download-button bill-modal-cancel-button-disabled"
            : "report-download-button bill-modal-cancel-button"
        }
      />

      <ActionButton
        onClick={handleDownload}
        text={downloadText}
        isIcon={true}
        buttonStyle={
          isDownloadDisabled
            ? "report-download-button download-button-style bill-modal-update-button-disabled-bg-color"
            : "report-download-button download-button-style bill-modal-update-button-bg-color"
        }
        disabled={isDownloadDisabled}
      />
    </Grid>
  );
};
