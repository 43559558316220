import React from "react";
import { TableCell, TableHead, TableRow, Typography } from "@mui/material";
import "../../OrderPage.css";

// ===================================================|| ORDER DETAILS TABLE HEADER ||=================================================== //

export const OrderSelectedDetailsTableHeader = ({ headers }) => {
  return (
    <TableHead>
      <TableRow>
        {headers?.map((header, index) => (
          <TableCell key={index}>
            {typeof header.label === "string" ? (
              <Typography className="bill-modal-font bold-text">
                {header.label}
              </Typography>
            ) : (
              header.label
            )}
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
};
