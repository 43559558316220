import React from "react";
import { TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { uiTexts } from "../../../Utils/uiTexts";

// ============================================|| ACCORDION DETAILS SUMMARY TABLE HEADER ||============================================ //

export const AccordionDetailsSummaryTableHeader = () => {
  const {
    labels: {
      orderedDateText,
      brokerNameText,
      orderedBagsText,
      dispatchedBagsText,
      itemNameText,
    },
    headerLabels: { pendingBagsText },
  } = uiTexts;

  const headers = [
    { label: orderedDateText },
    { label: itemNameText },
    { label: brokerNameText },
    { label: orderedBagsText },
    { label: dispatchedBagsText },
    { label: pendingBagsText },
  ];

  return (
    <TableHead>
      <TableRow>
        {headers?.map((header, index) => (
          <TableCell key={index}>
            <Typography className="customers-tableCell">
              {header.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
