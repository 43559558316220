import React from "react";
import { Grid, TextField, Stack, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { uiTexts } from "../../../Utils/uiTexts";
import { handleDateSelect } from "../../../Utils/common";

// ===================================================|| DATE INPUT ||=================================================== //

export const DateInput = ({ selectedDate, setSelectedDate, setUpdateDate }) => {
  const {
    placeHolderTexts: { chooseDateAndTimeText, dateTimeInputFormat },
  } = uiTexts;

  return (
    <>
      <Grid item xs={3}>
        <Stack direction="row" className="payment-bill-modal-labels">
          <CalendarTodayIcon />

          <Typography className="payment-bill-modal-text">
            {chooseDateAndTimeText}
          </Typography>
        </Stack>
      </Grid>

      <Grid item xs={3.5}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label={chooseDateAndTimeText}
            format={dateTimeInputFormat}
            value={selectedDate}
            onChange={(newValue) =>
              handleDateSelect(newValue, setUpdateDate, setSelectedDate)
            }
            slots={(params) => (
              <TextField
                {...params}
                value={selectedDate?.format("YYYY-MM-DD") || ""}
                fullWidth
              />
            )}
            slotProps={{
              textField: {
                readOnly: true,
              },
            }}
          />
        </LocalizationProvider>
      </Grid>
    </>
  );
};
